/* eslint-disable class-methods-use-this */
import { authHttp } from '../http';

export default class AdmService {
  getUser(url) {
    return authHttp
      .get(process.env.REACT_APP_KSI_URL_USER + url)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  }

  get(url) {
    return authHttp
      .get(process.env.REACT_APP_KSI_URL_ADM + url)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  }

  post(url, dados) {
    return authHttp
      .post(process.env.REACT_APP_KSI_URL_ADM + url, dados)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  postUser(url, dados) {
    return authHttp
      .post(process.env.REACT_APP_KSI_URL_USER + url, dados)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  put(url, dados) {
    return authHttp
      .put(process.env.REACT_APP_KSI_URL_ADM + url, dados)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  delete(url) {
    return authHttp
      .delete(process.env.REACT_APP_KSI_URL_ADM + url)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getCore(url) {
    return authHttp
      .get(process.env.REACT_APP_KSI_URL_CORE + url)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  }
}
