import { configureStore } from '@reduxjs/toolkit';
import fakeReducer from './slices/fake';
import fakeApi from './apis/fake';

export const store = configureStore({
  reducer: {
    [fakeApi.reducerPath]: fakeApi.reducer,
    fake: fakeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(fakeApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {fakeApi: FakeApiState}
export type AppDispatch = typeof store.dispatch;
