import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';

type LocationState = {
  from: {
    pathname: string;
  };
};

export const AuthPage: React.FC = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation<LocationState>();

  const { from } = location.state || { from: { pathname: '/' } };

  if (keycloak.authenticated) {
    return <Redirect to={from} />;
  }

  keycloak.login();
  return <Redirect to={from} />;
};

export default injectIntl(AuthPage);
