import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

const TiposResiduo = (props) => {
  const dadosSalvarVazio = {
    id: '',
    descricao: '',
    taxaResiduo: 0,
  };

  const toast = useRef<Toast>(null);
  const basicoService = BasicoService();

  const [salvando, setSalvando] = useState(false);
  const [tentarSalvar, setTentarSalvar] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);

  const [mostrarDialogDeletar, setMostrarDialogDeletar] = useState(false);
  const [mostrarDialogCriarEditar, setMostrarDialogCriarEditar] = useState(false);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const consultarDadosTabela = () => {
    basicoService.get('/v1/tipos-residuo').then((dadosGet) => {
      setDadosTabela(dadosGet);
      setCarregandoDados(false);
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_COL_CAD_TIPPC') {
        temPermissao = true;
        consultarDadosTabela();
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const acaoSalvarDados = () => {
    setSalvando(true);
    setTentarSalvar(true);

    if (dadosSalvar.descricao.trim()) {
      if (dadosSalvar.id === '') {
        basicoService.post('/v1/tipos-residuo', dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setMostrarDialogCriarEditar(false);
          setDadosSalvar(dadosSalvarVazio);
          setSalvando(false);
          consultarDadosTabela();
        });
      } else {
        basicoService.put(`/v1/tipos-residuo/${dadosSalvar.id}`, dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados editados com sucesso.');
          setMostrarDialogCriarEditar(false);
          setDadosSalvar(dadosSalvarVazio);
          setSalvando(false);
          consultarDadosTabela();
        });
      }
    } else {
      setSalvando(false);
    }
  };

  const acaoDeletarDados = () => {
    setTentarSalvar(true);
    basicoService.delete(`/v1/tipos-residuo/${dadosSalvar.id}`).then(() => {
      mostrarToast('success', 'Sucesso!', 'Dados deletados com sucesso.');
      setSalvando(false);
      consultarDadosTabela();
    });
    setMostrarDialogDeletar(false);
    setDadosSalvar(dadosSalvarVazio);
  };

  const acaoMostrarDialogCriar = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogEditar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogDeletar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogDeletar(true);
  };

  const acaoFecharDialogs = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setMostrarDialogDeletar(false);
    setMostrarDialogCriarEditar(false);
  };

  const acaoInputDigitar = (e, dados) => {
    const val = e.target ? e.target.value : e.value;
    const _dadosSalvar = { ...dadosSalvar };
    _dadosSalvar[`${dados}`] = val;
    setDadosSalvar(_dadosSalvar);
  };

  const formatarDinheiro = (valor) => {
    return valor ? valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : undefined;
  };

  const templateTaxaResiduo = (rowData) => {
    return formatarDinheiro(rowData.taxaResiduo);
  };

  const cabecalhoTabela = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Tipos de Resíduo</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={acaoMostrarDialogCriar} />
    </div>
  );

  const botoesRodapeDialog = (
    <div style={{ paddingTop: '5px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-success"
        onClick={acaoSalvarDados}
      />
    </div>
  );

  const deleteTipoProtocoloDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Sim"
        icon="pi pi-check"
        className="p-button-danger"
        onClick={acaoDeletarDados}
      />
    </div>
  );

  const templateBotoesTabela = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          onClick={() => acaoMostrarDialogEditar(rowData)}
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          onClick={() => acaoMostrarDialogDeletar(rowData)}
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
        />
      </>
    );
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="descricao" header="Descrição" />
              <Column
                field="taxaResiduo"
                header="Taxa do Residuo"
                body={templateTaxaResiduo}
                bodyStyle={{ textAlign: 'end' }}
                headerStyle={{ textAlign: 'end' }}
              />
              <Column bodyStyle={{ textAlign: 'end' }} body={templateBotoesTabela} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={mostrarDialogCriarEditar}
        style={{ width: '600px' }}
        header="Cadastro de Tipo de Resíduo"
        modal
        className="p-fluid"
        footer={botoesRodapeDialog}
        onHide={acaoFecharDialogs}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              maxLength={50}
              id="descricao"
              value={dadosSalvar.descricao}
              onChange={(e) => acaoInputDigitar(e, 'descricao')}
              required
              autoFocus
              className={classNames({ 'p-invalid': tentarSalvar && !dadosSalvar.descricao })}
            />{' '}
            {tentarSalvar && !dadosSalvar.descricao && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="taxaResiduo">Taxa de Residuo</label>
            <InputNumber
              max={1000000000000}
              id="taxaResiduo"
              value={dadosSalvar.taxaResiduo}
              onChange={(e) => {
                acaoInputDigitar(e, 'taxaResiduo');
              }}
              required
              mode="decimal"
              minFractionDigits={2}
              maxFractionDigits={2}
              className={classNames({ 'p-invalid': tentarSalvar && !dadosSalvar.taxaResiduo })}
            />{' '}
            {tentarSalvar && !dadosSalvar.descricao && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogDeletar}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {dadosSalvar && (
            <span>
              Deseja remover <b>{dadosSalvar.descricao}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default TiposResiduo;
