import styled from 'styled-components';

export const StyledNavbar = styled.nav`
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
`;
