import { authHttp } from '../http';

export const USER = '/api/users';

export default function coreService() {
  return {
    get(url) {
      return authHttp
        .get(process.env.REACT_APP_KSI_URL_CORE + url)
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          return error;
        });
    },

    post(url, dados) {
      return authHttp
        .post(process.env.REACT_APP_KSI_URL_CORE + url, dados)
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    put(url, dados) {
      return authHttp
        .put(process.env.REACT_APP_KSI_URL_CORE + url, dados)
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    delete(url) {
      return authHttp
        .delete(process.env.REACT_APP_KSI_URL_CORE + url)
        .then((resp) => {
          return resp.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  };
}
