import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

interface Dados {
  id: string;
  ativo: boolean;
  bairro: string;
  cep: string;
  complemento: string;
  descricao: string;
  idCidade: string;
  logradouro: string;
  numero: string;
  latitude?: number;
  longitude?: number;
  cidade?: Record<string, unknown>;
}

const PontosColeta: React.FC<RouteComponentProps> = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCnAOfPYJt8EKxvUVCi7jlE1kt7OVqu4Nk',
  });

  const dadosSalvarVazio: Dados = {
    id: '',
    ativo: true,
    bairro: '',
    cep: '',
    complemento: '',
    descricao: '',
    idCidade: '',
    logradouro: '',
    numero: '',
  };

  const posicaoMapaVazio = {
    lat: 0,
    lng: 0,
  };

  const toast = useRef<Toast>(null);
  const basicoService = BasicoService();

  const [cepConsultado, setCepConsultado] = useState('');

  const [salvando, setSalvando] = useState(false);
  const [validarCampos, setValidarCampos] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);
  const [posicaoMapa, setPosicaoMapa] = useState(posicaoMapaVazio);

  const [autoCompleteCidadeSugestoes, setAutoCompleteCidadeSugestoes] = useState<any>(null);
  const [autoCompleteCidadeValorSelecionado, setAutoCompleteCidadeValorSelecionado] = useState<any>(null);

  const [abrirInfoMarker, setAbrirInfoMarker] = useState(false);
  const [mostrarDialogMapa, setMostrarDialogMapa] = useState(false);
  const [mostrarDialogDeletar, setMostrarDialogDeletar] = useState(false);
  const [mostrarDialogCriarEditar, setMostrarDialogCriarEditar] = useState(false);

  const testeDadosTela = (dados) => {
    if (dados !== null) {
      console.log('dados', dados);
    }
  };

  const consultarDadosTabela = () => {
    basicoService.get('/v1/pontos-coleta').then((dadosGet) => {
      setDadosTabela(dadosGet);
      setCarregandoDados(false);
      testeDadosTela(dadosGet);
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_COL_CAD_PC') {
        temPermissao = true;
        consultarDadosTabela();
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const acaoFecharDialogs = () => {
    setSalvando(false);
    setCepConsultado('');
    setValidarCampos(false);
    setMostrarDialogMapa(false);
    setMostrarDialogDeletar(false);
    setMostrarDialogCriarEditar(false);
    setAutoCompleteCidadeValorSelecionado(null);
  };

  const acaoSalvarDados = () => {
    setSalvando(true);
    setValidarCampos(true);

    if (dadosSalvar.cidade && dadosSalvar.cidade.id) {
      dadosSalvar.idCidade = `${dadosSalvar.cidade.id}`;
    }

    if (
      dadosSalvar.descricao.trim() &&
      dadosSalvar.idCidade.trim() &&
      dadosSalvar.cep.trim() &&
      dadosSalvar.bairro.trim() &&
      dadosSalvar.logradouro.trim() &&
      dadosSalvar.numero.trim()
    ) {
      if (dadosSalvar.id === '') {
        basicoService.post('/v1/pontos-coleta', dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          setDadosSalvar(dadosSalvarVazio);
          consultarDadosTabela();
        });
      } else {
        basicoService.put(`/v1/pontos-coleta/${dadosSalvar.id}`, dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados editados com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          setDadosSalvar(dadosSalvarVazio);
          consultarDadosTabela();
        });
      }
    } else {
      setSalvando(false);
    }
  };

  const acaoDeletarDados = () => {
    setValidarCampos(true);
    basicoService.delete(`/v1/pontos-coleta/${dadosSalvar.id}`).then(() => {
      mostrarToast('success', 'Sucesso!', 'Dados deletados com sucesso.');
      setSalvando(false);
      consultarDadosTabela();
    });
    setMostrarDialogDeletar(false);
    setDadosSalvar(dadosSalvarVazio);
  };

  const acaoMostrarDialogCriar = () => {
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogEditar = (dados) => {
    setAutoCompleteCidadeValorSelecionado(dados.cidade.descricao);
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogDeletar = (dados) => {
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogDeletar(true);
  };

  const onMarkerClick = () => {
    setAbrirInfoMarker(!abrirInfoMarker);
  };

  const acaoMostrarDialogMapa = (dados) => {
    setAbrirInfoMarker(false);
    setDadosSalvar({ ...dados });
    if (dados.latitude && dados.longitude) {
      setPosicaoMapa({
        lat: dados.latitude,
        lng: dados.longitude,
      });
    }
    setMostrarDialogMapa(true);
  };

  const acaoAutoCompleteCidadePesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/cidades/consulta?search=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompleteCidadeSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompleteCidadeSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteSelecionar = (dadosEvento) => {
    dadosSalvar.idCidade = dadosEvento.id;
  };

  const acaoAutoCompleteLimpar = (dados, campo) => {
    if (dados === '' || dados === null) {
      if (campo === 'cidade') {
        dadosSalvar.idCidade = '';
        setAutoCompleteCidadeValorSelecionado(dados);
      }
    } else if (campo === 'cidade') {
      setAutoCompleteCidadeValorSelecionado(dados);
    }
  };

  const acaoInputDigitar = (e, dados) => {
    if (dados === 'cep' && e.value.length === 8 && e.value !== cepConsultado) {
      setCepConsultado(e.value);
      basicoService.getCore(`/v1/enderecos/consultar-cep/${e.value}`).then((data) => {
        if (data) {
          const _dadosSalvar = { ...dadosSalvar };
          _dadosSalvar[`${'cep'}`] = data.cep;
          _dadosSalvar[`${'bairro'}`] = data.bairro;
          _dadosSalvar[`${'complemento'}`] = data.complemento;
          _dadosSalvar[`${'logradouro'}`] = data.logradouro;

          basicoService.get(`/v1/cidades/consulta?codigoIbge=${data.ibge}`).then((dataIbge) => {
            if (dataIbge[0]) {
              _dadosSalvar[`${'idCidade'}`] = dataIbge[0].id;
              setAutoCompleteCidadeValorSelecionado(dataIbge[0].descricao);
            }
            setDadosSalvar(_dadosSalvar);
          });
        } else {
          mostrarToast('warn', 'Aviso!', 'CEP inválido.');
        }
      });
    }
    const val = (e.target && e.target.value) || '';
    const _dadosSalvar = { ...dadosSalvar };
    _dadosSalvar[`${dados}`] = val;
    setDadosSalvar(_dadosSalvar);
  };

  const cabecalhoTabela = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Pontos de Coleta</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={acaoMostrarDialogCriar} />
    </div>
  );

  const botoesRodapeDialog = (
    <div style={{ paddingTop: '5px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-success"
        onClick={acaoSalvarDados}
      />
    </div>
  );

  const deleteTipoProtocoloDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Sim"
        icon="pi pi-check"
        className="p-button-danger"
        onClick={acaoDeletarDados}
      />
    </div>
  );

  const mapaDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Ok" icon="pi pi-check" className="p-button-info" onClick={acaoFecharDialogs} />
    </div>
  );

  const templateBotoesTabela = (rowData) => {
    return (
      <>
        {rowData.latitude && rowData.longitude && (
          <Button
            style={{ marginRight: '15px' }}
            icon="pi pi-map-marker"
            className="p-button-rounded p-button-outlined p-button-success"
            onClick={() => acaoMostrarDialogMapa(rowData)}
            tooltip="Mapa"
            tooltipOptions={{ position: 'top' }}
          />
        )}
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          onClick={() => acaoMostrarDialogEditar(rowData)}
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          onClick={() => acaoMostrarDialogDeletar(rowData)}
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
        />
      </>
    );
  };

  const formatEndereco = (dados) => {
    if (dados && dados.bairro && dados.logradouro && dados.numero) {
      const endereco = `${dados.bairro.charAt(0).toUpperCase() + dados.bairro.slice(1).toLowerCase()} - ${
        dados.logradouro.charAt(0).toUpperCase() + dados.logradouro.slice(1).toLowerCase()
      }, ${dados.numero}`;
      return endereco;
    }
    return '';
  };

  const templateEndereco = (rowData) => {
    const endereco = formatEndereco(rowData);
    return <span>{endereco}</span>;
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="descricao" header="Descrição" sortable />
              <Column field="cidade.descricao" header="Cidade" />
              <Column field="cep" header="CEP" />
              <Column header="Endereço" body={templateEndereco} />
              <Column field="numero" header="Número" />
              <Column bodyStyle={{ textAlign: 'end' }} body={templateBotoesTabela} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={mostrarDialogCriarEditar}
        style={{ width: '800px' }}
        header="Cadastro de Ponto de Coleta"
        modal
        className="p-fluid"
        footer={botoesRodapeDialog}
        onHide={acaoFecharDialogs}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              maxLength={50}
              id="descricao"
              value={dadosSalvar.descricao}
              onChange={(e) => acaoInputDigitar(e, 'descricao')}
              required
              autoFocus
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.descricao })}
            />{' '}
            {validarCampos && !dadosSalvar.descricao && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-2 p-lg-2">
            <label htmlFor="cep">CEP</label>
            <InputMask
              mask="99999-999"
              unmask
              id="cep"
              value={dadosSalvar.cep}
              onChange={(e) => acaoInputDigitar(e, 'cep')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.cep })}
            />{' '}
            {validarCampos && !dadosSalvar.cep && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-10 p-lg-10">
            <label htmlFor="cidade">Cidade</label>
            <AutoComplete
              value={autoCompleteCidadeValorSelecionado}
              suggestions={autoCompleteCidadeSugestoes}
              completeMethod={acaoAutoCompleteCidadePesquisa}
              field="descricao"
              minLength={3}
              onSelect={(e) => acaoAutoCompleteSelecionar(e.value)}
              onChange={(e) => acaoAutoCompleteLimpar(e.value, 'cidade')}
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.idCidade })}
            />{' '}
            {validarCampos && !dadosSalvar.idCidade && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-2 p-lg-2">
            <label htmlFor="numero">Número</label>
            <InputText
              maxLength={50}
              id="numero"
              value={dadosSalvar.numero}
              onChange={(e) => acaoInputDigitar(e, 'numero')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.numero })}
            />{' '}
            {validarCampos && !dadosSalvar.numero && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-10 p-lg-10">
            <label htmlFor="bairro">Bairro</label>
            <InputText
              maxLength={50}
              id="bairro"
              value={dadosSalvar.bairro}
              onChange={(e) => acaoInputDigitar(e, 'bairro')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.bairro })}
            />{' '}
            {validarCampos && !dadosSalvar.bairro && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="logradouro">Logradouro</label>
            <InputText
              maxLength={50}
              id="logradouro"
              value={dadosSalvar.logradouro}
              onChange={(e) => acaoInputDigitar(e, 'logradouro')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.logradouro })}
            />{' '}
            {validarCampos && !dadosSalvar.logradouro && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="complemento">Complemento</label>
            <InputText
              maxLength={50}
              id="complemento"
              value={dadosSalvar.complemento}
              onChange={(e) => acaoInputDigitar(e, 'complemento')}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogDeletar}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {dadosSalvar && (
            <span>
              Deseja remover <b>{dadosSalvar.descricao}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogMapa}
        style={{ width: '750px' }}
        header="Mapa do Ponto"
        modal
        footer={mapaDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ height: '500px' }}>
          {isLoaded ? (
            <GoogleMap mapContainerStyle={{ width: '100%', height: '100%' }} center={posicaoMapa} zoom={15}>
              <Marker position={posicaoMapa} clickable onClick={onMarkerClick}>
                {abrirInfoMarker && (
                  <InfoWindow position={posicaoMapa} onCloseClick={onMarkerClick}>
                    <div>
                      <p>
                        <b>Descrição:</b> {dadosSalvar.descricao}
                      </p>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
              <></>
            </GoogleMap>
          ) : (
            <></>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default PontosColeta;
