import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html,
  body {
    height: 100%;
    background-color: #e2e2eb;
  }

  div#root {
    height: 100%;
  }

  .App {
    height: 100%;
  }
`;
