/* eslint-disable react/no-array-index-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import jwt_decode from 'jwt-decode';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import ReactTooltip from 'react-tooltip';

import { keycloak } from '../../../utils/auth';
import AdmService from '../../../utils/services/admService';
import UsuarioService from '../../../utils/services/usuarioService';

interface Role {
  id: string;
  name: string;
  description: string;
  resume: string;
  ativo: boolean;
}

const Grupos = (props) => {
  let permissoes: any;

  const emptyGrupo = {
    id: '',
    name: '',
    roles: [{}],
  };

  const toast = useRef<any>();
  const [grupos, setGrupos] = useState<any[]>([]);
  const admService = new AdmService();
  const usuarioService = UsuarioService();

  const [salvando, setSalvando] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);
  const [grupo, setGrupo] = useState(emptyGrupo);

  const [todasPermissoes, setTodasPermissoes] = useState<any[]>([]);

  const [todasPermissoesBkp, setTodasPermissoesBkp] = useState<any[]>([]);

  const [grupoDialog, setGrupoDialog] = useState(false);
  const [deleteGrupoDialog, setDeleteGrupoDialog] = useState(false);

  const [filtrarPermissao, setFiltrarPermissao] = useState('');
  const [filtrandoPermissao, setFiltrandoPermissao] = useState(false);

  const tenant = useRef(localStorage.getItem('tenant')).current?.replaceAll('"', '');

  const consultarGrupos = () => {
    usuarioService.get('/v1/rolegroups').then((data) => {
      if (data) {
        setGrupos(data);
        setCarregandoDados(false);
      }
    });
  };

  const consultarRoles = () => {
    admService.get(`/roles/${tenant}`).then((data) => {
      if (data) {
        const controle = {
          ativo: false,
        };
        data = data.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        data.forEach((role) => {
          Object.assign(role, controle);
        });
        setTodasPermissoes(data);
        setTodasPermissoesBkp(data);
        setCarregandoDados(false);
      }
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);

    for (let i = 0; i < permissoes.length; i += 1) {
      if (permissoes[i] === '5R_CON_SIS_GR-ROLE') {
        temPermissao = true;
        consultarGrupos();
        consultarRoles();
      }
      if (i + 1 === permissoes.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const openNew = () => {
    const x = emptyGrupo;
    x.roles.splice(0, 1);

    consultarRoles();

    setGrupo(x);
    setSubmitted(false);
    setGrupoDialog(true);
  };

  const limparDialog = () => {
    setGrupo(emptyGrupo);
    setFiltrarPermissao('');
    consultarRoles();
  };

  const hideDialog = () => {
    consultarGrupos();
    setSalvando(false);
    setSubmitted(false);
    setGrupoDialog(false);
    limparDialog();
  };

  const editGrupo = (grupo) => {
    setSubmitted(false);
    setGrupo(grupo);

    todasPermissoes.forEach((role) => {
      grupo.roles.forEach((r) => {
        if (r.name === role.name) {
          role.ativo = true;
        }
      });
    });

    setGrupoDialog(true);
  };

  const confirmDeleteGrupo = (grupo) => {
    setGrupo(grupo);
    setDeleteGrupoDialog(true);
  };

  const hideDeleteGrupoDialog = () => {
    setDeleteGrupoDialog(false);
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _grupo = { ...grupo };
    _grupo[`${dados}`] = val;
    setGrupo(_grupo);
  };

  const filtrarRole = (valor) => {
    let bkpLista: Role[] = [];

    bkpLista.push(...todasPermissoesBkp);
    bkpLista = bkpLista.filter((e) => e.resume.toUpperCase().includes(valor.toUpperCase()));

    setTodasPermissoes(bkpLista);
  };

  const onInputChangeFiltroRole = (e) => {
    const val = (e.target && e.target.value) || '';

    setFiltrarPermissao(val);
    filtrarRole(val);
  };

  const deleteGrupo = () => {
    usuarioService.delete(`/v1/rolegroups/${grupo.id}`).then(() => {
      mostrarToast('info', 'Aviso!', 'Grupo deletado.');
      consultarGrupos();
    });
    setDeleteGrupoDialog(false);
    setGrupo(emptyGrupo);
  };

  const saveGrupo = () => {
    setSubmitted(true);

    const roles: Role[] = [];
    for (let role of todasPermissoes) {
      if (role.ativo) {
        delete role.ativo;
        delete role.description;
        delete role.id;

        role = role.name;
        roles.push(role);
      }
    }

    grupo.roles = todasPermissoes.filter((r) => r.ativo);

    const grupoEnviar = {
      id: grupo.id,
      name: grupo.name,
      roleIds: roles,
    };

    if (!grupoEnviar.id) {
      if (grupoEnviar.name.trim() && roles[0]) {
        setSubmitted(false);
        setSalvando(true);
        usuarioService.post(`/v1/rolegroups`, grupoEnviar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Grupo criado.');
          hideDialog();
          consultarGrupos();
        });
      }
    } else if (grupoEnviar.name.trim() && roles[0]) {
      setSubmitted(false);
      setSalvando(true);
      usuarioService.put(`/v1/rolegroups`, grupoEnviar).then(() => {
        mostrarToast('success', 'Sucesso!', 'Grupo editado.');
        hideDialog();
        consultarGrupos();
      });
    }
  };

  const ativarItem = (flag, role) => {
    const permissoes: Role[] = [];
    for (let index = 0; index < todasPermissoes.length; index += 1) {
      permissoes.push(todasPermissoes[index]);
      if (permissoes[index].name === role.name) {
        permissoes[index].ativo = flag;
      }
    }
    setTodasPermissoesBkp(permissoes);
    setTodasPermissoes(permissoes);
  };

  const Permissoes = () => {
    return (
      <>
        <div className="p-fluid p-grid" style={{ padding: '10px', marginBottom: '15px' }}>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="filtroRole">Permissão</label>
            <InputText
              maxLength={100}
              id="filtroRole"
              name="filtroRole"
              key="filtroRole"
              value={filtrarPermissao}
              autoFocus={filtrandoPermissao}
              onFocus={() => setFiltrandoPermissao(true)}
              onBlur={() => setFiltrandoPermissao(false)}
              onChange={(e) => onInputChangeFiltroRole(e)}
            />
          </div>
        </div>
        {todasPermissoes.map((role, i) => {
          const nome = role.resume ?? role.name;
          return (
            <div className="p-fluid p-mb-4" style={{ display: 'flex', justifyContent: 'space-around' }} key={i}>
              <div style={{ display: 'inline', marginRight: '25px', minWidth: '50%' }}>
                <label htmlFor={role.name} data-tip data-for={role.name}>
                  {nome}
                </label>
                <ReactTooltip id={role.name} effect="solid">
                  <span>{role.description}</span>
                </ReactTooltip>
              </div>
              <div style={{ display: 'inline' }}>
                <ToggleButton
                  checked={role.ativo}
                  onChange={(e) => ativarItem(e.value, role)}
                  onLabel="Ativo"
                  offLabel="Inativo"
                  style={{ display: 'inline', width: '10em' }}
                />
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
          onClick={() => editGrupo(rowData)}
        />
        {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" tooltip="Excluir" tooltipOptions={{ position: 'top' }} onClick={() => confirmDeleteGrupo(rowData)} /> */}
      </>
    );
  };

  const deleteGrupoDialogFooter = (
    <>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGrupoDialog} />
      <Button label="Sim" icon="pi pi-check" className="p-button-danger" onClick={deleteGrupo} />
    </>
  );

  const grupoDialogFooter = (
    <>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button label="Salvar" disabled={salvando} icon="pi pi-check" className="p-button-success" onClick={saveGrupo} />
    </>
  );

  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Grupos</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNew} />
    </div>
  );

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div style={{ width: '-webkit-fill-available' }}>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={grupos}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={header}
            >
              <Column field="name" header="Nome" />
              <Column bodyStyle={{ textAlign: 'end' }} body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={grupoDialog}
        style={{ width: '900px' }}
        header="Cadastro de Grupo"
        modal
        className="p-fluid"
        footer={grupoDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-fluid p-grid" style={{ padding: '10px' }}>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="firstName">Nome</label>
            <InputText
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="firstName"
              value={grupo.name}
              onChange={(e) => onInputChange(e, 'name')}
              required
              autoFocus
              className={classNames({ 'p-invalid': submitted && !grupo.name })}
            />
            {submitted && !grupo.name && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>

        <div className="p-fluid p-grid" style={{ padding: '10px' }}>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <h3 style={{ borderBottom: '1px solid #21212150', padding: '5px' }}>Permissões</h3>
            {submitted && !grupo.roles[0] && <small className="p-error">Campo obrigatório.</small>}
            <Permissoes />
            {submitted && !grupo.roles[0] && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteGrupoDialog}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteGrupoDialogFooter}
        onHide={hideDeleteGrupoDialog}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {grupo && (
            <span>
              Deseja remover o grupo <b>{grupo.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default Grupos;
