import React from 'react';
import { A11yButtonProps } from './types';

function keyDownA11y(handler) {
  return function onKeyDown(event) {
    if (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key)) {
      handler();
    }
  };
}

const A11yButton: React.FC<A11yButtonProps> = ({ elementType, elementProps, onClick, ...props }) => {
  return React.createElement(elementType, {
    ...props,
    ...elementProps,
    onClick,
    onKeyDown: keyDownA11y(onClick),
    role: 'button',
    // Add other props that might be necessary, like "tabIndex: 0,"
  });
};

export default A11yButton;
