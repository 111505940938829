import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';

import { AutoComplete } from 'primereact/autocomplete';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

interface Dados {
  id: string;
  cep: string;
  valor: number;
  bairro: string;
  recurso: string;
  endereco: string;
  descricao: string;
  idCidade: string;
  idEmpresa: string;
  idTipoObra: string;
  estagioObra: string;
  status: string;
  cidade?: Record<string, unknown>;
  tipoObra?: Record<string, unknown>;
  empresa?: Record<string, unknown>;
}

const Obras: React.FC<RouteComponentProps> = (props) => {
  const dadosSalvarVazio: Dados = {
    id: '',
    cep: '',
    valor: 0,
    bairro: '',
    recurso: '',
    endereco: '',
    descricao: '',
    idCidade: '',
    idEmpresa: '',
    idTipoObra: '',
    estagioObra: 'CONCLUIDO',
    status: 'ATIVA',
  };

  const toast = useRef<Toast>(null);
  const basicoService = BasicoService();

  const [salvando, setSalvando] = useState(false);
  const [validarCampos, setValidarCampos] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);

  const [autoCompleteEmpresaSugestoes, setAutoCompleteEmpresaSugestoes] = useState<any>(null);
  const [autoCompleteEmpresaValorSelecionado, setAutoCompleteEmpresaValorSelecionado] = useState<any>(null);

  const [autoCompleteTipoObraSugestoes, setAutoCompleteTipoObraSugestoes] = useState<any>(null);
  const [autoCompleteTipoObraValorSelecionado, setAutoCompleteTipoObraValorSelecionado] = useState<any>(null);

  const [autoCompleteCidadeSugestoes, setAutoCompleteCidadeSugestoes] = useState<any>(null);
  const [autoCompleteCidadeValorSelecionado, setAutoCompleteCidadeValorSelecionado] = useState<any>(null);

  const [mostrarDialogDeletar, setMostrarDialogDeletar] = useState(false);
  const [mostrarDialogCriarEditar, setMostrarDialogCriarEditar] = useState(false);

  const consultarDadosTabela = () => {
    basicoService.get('/v1/obras').then((dadosGet) => {
      setDadosTabela(dadosGet);
      setCarregandoDados(false);
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_APP_OBR_OBR') {
        temPermissao = true;
        consultarDadosTabela();
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const acaoFecharDialogs = () => {
    setSalvando(false);
    setValidarCampos(false);
    setMostrarDialogDeletar(false);
    setMostrarDialogCriarEditar(false);
    setAutoCompleteEmpresaValorSelecionado(null);
    setAutoCompleteCidadeValorSelecionado(null);
    setAutoCompleteTipoObraValorSelecionado(null);
  };

  const acaoSalvarDados = () => {
    setSalvando(true);
    setValidarCampos(true);

    if (!dadosSalvar.idCidade && dadosSalvar.cidade && dadosSalvar.cidade.id) {
      dadosSalvar.idCidade = `${dadosSalvar.cidade.id}`;
    }

    if (!dadosSalvar.idEmpresa && dadosSalvar.empresa && dadosSalvar.empresa.id) {
      dadosSalvar.idEmpresa = `${dadosSalvar.empresa.id}`;
    }

    if (!dadosSalvar.idTipoObra && dadosSalvar.tipoObra && dadosSalvar.tipoObra.id) {
      dadosSalvar.idTipoObra = `${dadosSalvar.tipoObra.id}`;
    }

    if (
      dadosSalvar.descricao.trim() &&
      dadosSalvar.idCidade.trim() &&
      dadosSalvar.cep.trim() &&
      dadosSalvar.bairro.trim() &&
      dadosSalvar.idEmpresa.trim() &&
      dadosSalvar.idTipoObra.trim() &&
      dadosSalvar.recurso.trim() &&
      dadosSalvar.endereco.trim() &&
      dadosSalvar.valor
    ) {
      if (dadosSalvar.id === '') {
        basicoService.post('/v1/obras', dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          setDadosSalvar(dadosSalvarVazio);
          consultarDadosTabela();
        });
      } else {
        basicoService.put(`/v1/obras/${dadosSalvar.id}`, dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados editados com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          setDadosSalvar(dadosSalvarVazio);
          consultarDadosTabela();
        });
      }
    } else {
      setSalvando(false);
    }
  };

  const acaoDeletarDados = () => {
    setValidarCampos(true);
    basicoService.delete(`/v1/obras/${dadosSalvar.id}`).then(() => {
      mostrarToast('success', 'Sucesso!', 'Dados deletados com sucesso.');
      setSalvando(false);
      consultarDadosTabela();
    });
    setMostrarDialogDeletar(false);
    setDadosSalvar(dadosSalvarVazio);
  };

  const acaoMostrarDialogCriar = () => {
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogEditar = (dados) => {
    setAutoCompleteTipoObraValorSelecionado(dados.tipoObra.descricao);
    setAutoCompleteEmpresaValorSelecionado(dados.empresa.descricao);
    setAutoCompleteCidadeValorSelecionado(dados.cidade.descricao);
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogDeletar = (dados) => {
    setSalvando(false);
    setValidarCampos(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogDeletar(true);
  };

  const acaoAutoCompleteCidadePesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/cidades/consulta?search=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompleteCidadeSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompleteCidadeSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteEmpresaPesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/empresas?search=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompleteEmpresaSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompleteEmpresaSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteTipoObraPesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/tipos-obra?search=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompleteTipoObraSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompleteTipoObraSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteSelecionar = (dadosEvento, campo) => {
    if (campo === 'cidade') {
      dadosSalvar.idCidade = dadosEvento.id;
    }
    if (campo === 'empresa') {
      dadosSalvar.idEmpresa = dadosEvento.id;
    }
    if (campo === 'tipoObra') {
      dadosSalvar.idTipoObra = dadosEvento.id;
    }
  };

  const acaoAutoCompleteLimpar = (dados, campo) => {
    if (dados === '' || dados === null) {
      if (campo === 'cidade') {
        dadosSalvar.idCidade = '';
        setAutoCompleteCidadeValorSelecionado(dados);
      }
      if (campo === 'empresa') {
        dadosSalvar.idEmpresa = '';
        setAutoCompleteEmpresaValorSelecionado(dados);
      }
      if (campo === 'tipoObra') {
        dadosSalvar.idTipoObra = '';
        setAutoCompleteTipoObraValorSelecionado(dados);
      }
    } else {
      if (campo === 'cidade') {
        setAutoCompleteCidadeValorSelecionado(dados);
      }
      if (campo === 'empresa') {
        setAutoCompleteEmpresaValorSelecionado(dados);
      }
      if (campo === 'tipoObra') {
        setAutoCompleteTipoObraValorSelecionado(dados);
      }
    }
  };

  const acaoInputDigitar = (e, dados) => {
    const val = (e.target && e.target.value) || e.value || '';
    const _dadosSalvar = { ...dadosSalvar };
    _dadosSalvar[`${dados}`] = val;
    setDadosSalvar(_dadosSalvar);
  };

  const cabecalhoTabela = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Obras</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={acaoMostrarDialogCriar} />
    </div>
  );

  const botoesRodapeDialog = (
    <div style={{ paddingTop: '5px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-success"
        onClick={acaoSalvarDados}
      />
    </div>
  );

  const deleteTipoProtocoloDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Sim"
        icon="pi pi-check"
        className="p-button-danger"
        onClick={acaoDeletarDados}
      />
    </div>
  );

  const templateBotoesTabela = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          onClick={() => acaoMostrarDialogEditar(rowData)}
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          onClick={() => acaoMostrarDialogDeletar(rowData)}
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
        />
      </>
    );
  };

  const formatEndereco = (dados) => {
    if (dados && dados.bairro && dados.endereco) {
      const endereco = `${dados.bairro.charAt(0).toUpperCase() + dados.bairro.slice(1).toLowerCase()} - ${
        dados.endereco.charAt(0).toUpperCase() + dados.endereco.slice(1).toLowerCase()
      }`;
      return endereco;
    }
    return '';
  };

  const templateEndereco = (rowData) => {
    const endereco = formatEndereco(rowData);
    return <span>{endereco}</span>;
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div style={{ width: '-webkit-fill-available' }}>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="descricao" header="Descrição" sortable />
              <Column field="cidade.descricao" header="Cidade" sortable />
              <Column field="cep" header="CEP" />
              <Column header="Endereço" body={templateEndereco} />
              <Column field="empresa.descricao" header="Empresa" sortable />
              <Column field="estagioObra" header="Estágio" />
              <Column field="status" header="Status" sortable />
              <Column bodyStyle={{ textAlign: 'end' }} body={templateBotoesTabela} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={mostrarDialogCriarEditar}
        style={{ width: '800px' }}
        header="Cadastro de Obra"
        modal
        className="p-fluid"
        footer={botoesRodapeDialog}
        onHide={acaoFecharDialogs}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              maxLength={50}
              id="descricao"
              value={dadosSalvar.descricao}
              onChange={(e) => acaoInputDigitar(e, 'descricao')}
              required
              autoFocus
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.descricao })}
            />{' '}
            {validarCampos && !dadosSalvar.descricao && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="cidade">Cidade</label>
            <AutoComplete
              value={autoCompleteCidadeValorSelecionado}
              suggestions={autoCompleteCidadeSugestoes}
              completeMethod={acaoAutoCompleteCidadePesquisa}
              field="descricao"
              minLength={3}
              onSelect={(e) => acaoAutoCompleteSelecionar(e.value, 'cidade')}
              onChange={(e) => acaoAutoCompleteLimpar(e.value, 'cidade')}
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.idCidade })}
            />{' '}
            {validarCampos && !dadosSalvar.idCidade && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="cep">CEP</label>
            <InputMask
              mask="99999-999"
              unmask
              id="cep"
              value={dadosSalvar.cep}
              onChange={(e) => acaoInputDigitar(e, 'cep')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.cep })}
            />{' '}
            {validarCampos && !dadosSalvar.cep && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="bairro">Bairro</label>
            <InputText
              maxLength={50}
              id="bairro"
              value={dadosSalvar.bairro}
              onChange={(e) => acaoInputDigitar(e, 'bairro')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.bairro })}
            />{' '}
            {validarCampos && !dadosSalvar.bairro && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="empresa">Empresa</label>
            <AutoComplete
              value={autoCompleteEmpresaValorSelecionado}
              suggestions={autoCompleteEmpresaSugestoes}
              completeMethod={acaoAutoCompleteEmpresaPesquisa}
              field="descricao"
              minLength={3}
              onSelect={(e) => acaoAutoCompleteSelecionar(e.value, 'empresa')}
              onChange={(e) => acaoAutoCompleteLimpar(e.value, 'empresa')}
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.idEmpresa })}
            />{' '}
            {validarCampos && !dadosSalvar.idEmpresa && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="tipoObra">Tipo de Obra</label>
            <AutoComplete
              value={autoCompleteTipoObraValorSelecionado}
              suggestions={autoCompleteTipoObraSugestoes}
              completeMethod={acaoAutoCompleteTipoObraPesquisa}
              field="descricao"
              minLength={3}
              onSelect={(e) => acaoAutoCompleteSelecionar(e.value, 'tipoObra')}
              onChange={(e) => acaoAutoCompleteLimpar(e.value, 'tipoObra')}
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.idTipoObra })}
            />{' '}
            {validarCampos && !dadosSalvar.idTipoObra && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="recurso">Recurso</label>
            <InputText
              maxLength={50}
              id="recurso"
              value={dadosSalvar.recurso}
              onChange={(e) => acaoInputDigitar(e, 'recurso')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.recurso })}
            />{' '}
            {validarCampos && !dadosSalvar.recurso && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="endereco">Endereço</label>
            <InputText
              maxLength={50}
              id="endereco"
              value={dadosSalvar.endereco}
              onChange={(e) => acaoInputDigitar(e, 'endereco')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.endereco })}
            />{' '}
            {validarCampos && !dadosSalvar.endereco && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="valor">Valor</label>
            <InputNumber
              max={1000000000000}
              id="valor"
              mode="decimal"
              locale="pt-BR"
              minFractionDigits={2}
              value={dadosSalvar.valor}
              onChange={(e) => acaoInputDigitar(e, 'valor')}
              required
              className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.valor })}
            />{' '}
            {validarCampos && !dadosSalvar.valor && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogDeletar}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {dadosSalvar && (
            <span>
              Deseja remover <b>{dadosSalvar.descricao}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default Obras;
