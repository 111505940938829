import React, { useState, useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';

import Avatar from 'react-avatar';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import BasicoService from '../../../utils/services/basicoService';
import { keycloak } from '../../../utils/auth';

import '../../../../index.css';
import 'primeflex/primeflex.css';

const TrocarSenha = () => {
  const dadosSalvarVazio = {
    email: '',
    username: '',
    password: '',
    oldPassword: '',
    userKeycloakId: '',
    confirmNovaSenha: '',
  };

  const toast = useRef<any>();
  const basicoService = BasicoService();

  const [salvando, setSalvando] = useState(false);
  const [validarCampos, setValidarCampos] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);

  // const [confirmNovaSenha, setConfirmNovaSenha] = useState<any>();

  const [emailUsuario, setEmailUsuario] = useState<any>();
  const [usernameUsuario, setUsernameUsuario] = useState<any>();

  // const consultarDadosTabela = (username) => {
  //   basicoService.getUrlUser(`/v1/users/details?username=${username}`).then((data) => {

  //     setEmailUsuario(data.email);
  //     setUsernameUsuario(data.firstName);
  //     dadosSalvarVazio.email = data.email;
  //     dadosSalvarVazio.username = data.username;
  //     dadosSalvarVazio.userKeycloakId = data.keycloakUserId;
  //     setCarregandoDados(false);
  //   });
  // };

  const dadosKeycloak = () => {
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    console.log('Token', decodedHeader);

    if (decodedHeader.name) {
      setUsernameUsuario(decodedHeader.name);
      dadosSalvarVazio.username = decodedHeader.name;
    } else if (decodedHeader.given_name) {
      setUsernameUsuario(decodedHeader.given_name);
      dadosSalvarVazio.username = decodedHeader.given_name;
    } else if (decodedHeader.preferred_username) {
      setUsernameUsuario(decodedHeader.preferred_username);
      dadosSalvarVazio.username = decodedHeader.preferred_username;
    }

    if (decodedHeader.email) {
      setEmailUsuario(decodedHeader.email);
      dadosSalvarVazio.email = decodedHeader.email;
    }

    setCarregandoDados(false);
  };

  useEffect(() => {
    dadosKeycloak();
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _usuario = { ...dadosSalvar };
    _usuario[`${dados}`] = val;
    setDadosSalvar(_usuario);
  };

  const botaoSalvar = () => {
    setSalvando(true);
    setValidarCampos(true);

    if (
      dadosSalvar.oldPassword.trim().length >= 6 &&
      dadosSalvar.password.trim().length >= 6 &&
      dadosSalvar.confirmNovaSenha.trim().length >= 6 &&
      dadosSalvar.password.trim() === dadosSalvar.confirmNovaSenha.trim()
    ) {
      basicoService.putUrlUser('/v1/users/password', dadosSalvar).then((retorno) => {
        if (retorno.error) {
          mostrarToast('error', 'Aviso!', 'Ocorreu um erro ao tentar salvar.');
          setSalvando(false);
        } else {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setValidarCampos(false);
          setSalvando(false);
          dadosSalvarVazio.email = '';
          dadosSalvarVazio.username = '';
          dadosSalvarVazio.userKeycloakId = '';
          setDadosSalvar(dadosSalvarVazio);
        }
      });
    } else {
      setSalvando(false);
    }
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div className="p-fluid p-grid card" style={{ margin: '20px', padding: '0' }}>
          <div
            className="p-col-12 p-md-12"
            style={{
              borderBottom: '1px solid #dbdbdb',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2 style={{ margin: '15px' }}>Alteração de Senha</h2>
          </div>

          <div className="p-col-12 p-md-12" style={{ padding: '20px' }}>
            <div style={{ padding: '0px 15px 10px 15px', display: 'flex', justifyContent: 'center' }}>
              <Avatar size="40" facebookId="100008343750912" />
              <div style={{ display: 'block', paddingLeft: '10px' }}>
                <p style={{ fontWeight: 'bolder', color: '#181d32', margin: 0, fontSize: '15px' }}>{usernameUsuario}</p>
                <p style={{ margin: 0, fontSize: '12px' }}>{emailUsuario}</p>
              </div>
            </div>

            <div className="p-grid">
              <div style={{ width: '100%' }}>
                <div className="p-sm-12 p-md-6 p-lg-6">
                  <label htmlFor="cpf">CPF</label>
                  <InputText disabled maxLength={14} value="232.955.460-53" onChange={(e) => onInputChange(e, 'cpf')} />
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <div className="p-sm-12 p-md-6 p-lg-6">
                  <label htmlFor="oldPassword">Senha antiga</label>
                  <Password
                    feedback={false}
                    maxLength={50}
                    value={dadosSalvar.oldPassword}
                    onChange={(e) => onInputChange(e, 'oldPassword')}
                    className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.oldPassword })}
                  />
                  {validarCampos && !dadosSalvar.oldPassword && <small className="p-error">Campo obrigatório.</small>}
                </div>
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="password">Nova senha</label>
                <Password
                  feedback={false}
                  maxLength={50}
                  value={dadosSalvar.password}
                  onChange={(e) => onInputChange(e, 'password')}
                  className={classNames({ 'p-invalid': validarCampos && !dadosSalvar.password })}
                />
                {validarCampos && !(dadosSalvar.password.length >= 6) && (
                  <small className="p-error">A senha deve conter no mínimo 6 caracteres.</small>
                )}
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="confirmNovaSenha">Confirmar nova senha</label>
                <Password
                  feedback={false}
                  maxLength={50}
                  value={dadosSalvar.confirmNovaSenha}
                  onChange={(e) => onInputChange(e, 'confirmNovaSenha')}
                  className={classNames({
                    'p-invalid': validarCampos && dadosSalvar.confirmNovaSenha !== dadosSalvar.password,
                  })}
                />
                {validarCampos && dadosSalvar.confirmNovaSenha !== dadosSalvar.password && (
                  <small className="p-error">O campo esta diferente da senha.</small>
                )}
              </div>
            </div>

            <div className="p-col-12 p-md-12" style={{ textAlign: 'end' }}>
              <Button
                disabled={salvando}
                style={{ width: 'auto' }}
                label="Salvar"
                icon="pi pi-check"
                className="p-button-success"
                onClick={botaoSalvar}
              />
            </div>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Toast ref={toast} />
    </div>
  );
};

export default TrocarSenha;
