import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

const TiposObra: React.FC<RouteComponentProps> = (props) => {
  const dadosSalvarVazio = {
    id: '',
    descricao: '',
  };

  const toast = useRef<Toast>(null);
  const basicoService = BasicoService();

  const [filtro, setFiltro] = useState<any>({ descricaoFiltro: '' });

  const [salvando, setSalvando] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);
  const [tentarSalvar, setTentarSalvar] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);

  const [mostrarDialogDeletar, setMostrarDialogDeletar] = useState(false);
  const [mostrarDialogCriarEditar, setMostrarDialogCriarEditar] = useState(false);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const consultarDadosTabela = () => {
    basicoService.get(`/v1/tipos-obra?descricao=${filtro.descricaoFiltro}`).then((dadosGet) => {
      setDadosTabela(dadosGet);
      setCarregandoDados(false);
      console.log(dadosGet);
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_APP_OBR_TIPOBR') {
        temPermissao = true;
        consultarDadosTabela();
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const acaoSalvarDados = () => {
    setSalvando(true);
    setTentarSalvar(true);

    if (dadosSalvar.descricao.trim()) {
      if (dadosSalvar.id === '') {
        basicoService.post('/v1/tipos-obra', dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setSalvando(false);
          consultarDadosTabela();
        });
      } else {
        basicoService.put(`/v1/tipos-obra/${dadosSalvar.id}`, dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados editados com sucesso.');
          setSalvando(false);
          consultarDadosTabela();
        });
      }

      setMostrarDialogCriarEditar(false);
      setDadosSalvar(dadosSalvarVazio);
    } else {
      setSalvando(false);
    }
  };

  const acaoDeletarDados = () => {
    setTentarSalvar(true);
    basicoService.delete(`/v1/tipos-obra/${dadosSalvar.id}`).then((retorno) => {
      if (retorno.error) {
        mostrarToast('error', 'Erro!', retorno.error);
        setSalvando(false);
        consultarDadosTabela();
      } else {
        mostrarToast('success', 'Sucesso!', 'Dados deletados com sucesso.');
        setSalvando(false);
        consultarDadosTabela();
      }
    });
    setMostrarDialogDeletar(false);
    setDadosSalvar(dadosSalvarVazio);
  };

  const AbrirFiltro = () => {
    if (filtroAberto === true) {
      filtro.descricaoFiltro = '';
      consultarDadosTabela();
    }
    setFiltroAberto(!filtroAberto);
  };

  const acaoMostrarDialogCriar = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogEditar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogDeletar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogDeletar(true);
  };

  const acaoFecharDialogs = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setMostrarDialogDeletar(false);
    setMostrarDialogCriarEditar(false);
  };

  const acaoInputDigitar = (e, dados) => {
    if (dados === 'descricaoFiltro') {
      const val = (e.target && e.target.value) || '';
      const _filtro = { ...filtro };
      _filtro[`${dados}`] = val;
      setFiltro(_filtro);
    } else {
      const val = (e.target && e.target.value) || '';
      const _dadosSalvar = { ...dadosSalvar };
      _dadosSalvar[`${dados}`] = val;
      setDadosSalvar(_dadosSalvar);
    }
  };

  const cabecalhoTabela = (
    <div className="p-fluid p-grid">
      <div className="p-sm-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="table-header">
          <h5 className="p-m-0">Consulta de Tipos de Obra</h5>
        </div>

        <div style={{ display: 'flex' }}>
          <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={acaoMostrarDialogCriar} />
          <Button type="button" className="p-button-text p-button-rounded" onClick={AbrirFiltro}>
            <p style={{ margin: '0 5px 0 0' }}>Filtros</p>
            {!filtroAberto && <i className="pi pi-angle-down" style={{ fontSize: '20px' }} />}
            {filtroAberto && <i className="pi pi-angle-up" style={{ fontSize: '20px' }} />}
          </Button>
        </div>
      </div>

      {filtroAberto && (
        <div className="p-sm-12 p-grid" style={{ justifyContent: 'space-between' }}>
          <div className="p-sm-12 p-md-6 p-lg-6">
            <p style={{ margin: '5px', fontWeight: 300 }}>Descrição</p>
            <InputText
              maxLength={50}
              style={{ fontWeight: 300 }}
              value={filtro.descricaoFiltro}
              onChange={(e) => acaoInputDigitar(e, 'descricaoFiltro')}
            />
          </div>

          <div style={{ alignSelf: 'flex-end', textAlign: 'end' }} className="p-sm-12 p-md-2 p-lg-2">
            <Button
              style={{ width: 'auto' }}
              label="Pesquisar"
              className="p-button-outlined"
              onClick={consultarDadosTabela}
            />
          </div>
        </div>
      )}
    </div>
  );

  const botoesRodapeDialog = (
    <div style={{ paddingTop: '5px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-success"
        onClick={acaoSalvarDados}
      />
    </div>
  );

  const deleteTipoProtocoloDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Sim"
        icon="pi pi-check"
        className="p-button-danger"
        onClick={acaoDeletarDados}
      />
    </div>
  );

  const templateBotoesTabela = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          onClick={() => acaoMostrarDialogEditar(rowData)}
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          onClick={() => acaoMostrarDialogDeletar(rowData)}
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
        />
      </>
    );
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="descricao" header="Descrição" />
              <Column bodyStyle={{ textAlign: 'end' }} body={templateBotoesTabela} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={mostrarDialogCriarEditar}
        style={{ width: '600px' }}
        header="Cadastro de Tipo de Obra"
        modal
        className="p-fluid"
        footer={botoesRodapeDialog}
        onHide={acaoFecharDialogs}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="descricao">Descrição</label>
            <InputText
              maxLength={50}
              id="descricao"
              value={dadosSalvar.descricao}
              onChange={(e) => acaoInputDigitar(e, 'descricao')}
              required
              autoFocus
              className={classNames({ 'p-invalid': tentarSalvar && !dadosSalvar.descricao })}
            />{' '}
            {tentarSalvar && !dadosSalvar.descricao && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogDeletar}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {dadosSalvar && (
            <span>
              Deseja remover <b>{dadosSalvar.descricao}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default TiposObra;
