/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { ToggleButton } from 'primereact/togglebutton';
import ReactTooltip from 'react-tooltip';
import BasicoService from '../../../utils/services/basicoService';

import '../../../../index.css';
import 'primeflex/primeflex.css';

const Parametros: React.FC<RouteComponentProps> = () => {
  const dadosSalvarVazio = {
    id: undefined,
    emailRelatorioPev: '',
    idCidade: '',
    controlaSmsResiduo: false,
    distanciaResiduos: 0,
    // horasValidadeEmail: 0,
    // valorResiduo: 0,
    // caminhoArquivoResiduo: '',
    // linkRecuperacaoSenha: '',
    chaveApiGeocoding: '',
    urlServicoSms: '',
    keyServicoSms: '',
    emailUsername: '',
    emailPassword: '',
    emailHost: '',
    emailPort: '',
    ouvidoria: false,
  };

  const toast = useRef<Toast>(null);
  const basicoService = BasicoService();

  const [salvando, setSalvando] = useState(false);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);
  const [flagOuvidoria, setFlagOuvidoria] = useState(false);

  const consultarDadosTabela = () => {
    basicoService.get('/v1/parametros').then((data) => {
      if (data[0]) {
        data[0].idCidade = data[0].cidade.id;
        setFlagOuvidoria(data[0].ouvidoria);

        setDadosSalvar(data[0]);
      }
    });
  };

  useEffect(() => {
    consultarDadosTabela();
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const onInputChange = (e, dados) => {
    if (e.originalEvent) {
      // input numerico
      const val = e.value || '';
      const _usuario = { ...dadosSalvar };
      _usuario[`${dados}`] = val;
      setDadosSalvar(_usuario);
    } else {
      // input texto
      const val = (e.target && e.target.value) || '';
      const _usuario = { ...dadosSalvar };
      _usuario[`${dados}`] = val;
      setDadosSalvar(_usuario);
    }
  };

  const botaoSalvar = () => {
    setSalvando(true);
    dadosSalvar.ouvidoria = flagOuvidoria;

    basicoService.put(`/v1/parametros/${dadosSalvar.id}`, dadosSalvar).then((retorno) => {
      if (retorno.error) {
        mostrarToast('error', 'Aviso!', 'Ocorreu um erro ao tentar salvar.');
        consultarDadosTabela();
        setSalvando(false);
      } else {
        mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
        consultarDadosTabela();
        setSalvando(false);
      }
    });
  };

  return (
    <div>
      <div className="p-mt-4">
        <div className="p-fluid p-grid card">
          <div
            className="p-col-12 p-md-12"
            style={{
              borderBottom: '1px solid #dbdbdb',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h2 style={{ margin: '15px' }}>Parâmetros</h2>
          </div>

          <div className="p-sm-12 p-md-6 p-lg-6">
            <label htmlFor="emailRelatorioPev">Email do Relatório</label>
            <div style={{ display: 'inline', marginRight: '25px' }}>
              <i
                className="pi pi-info-circle p-ml-2"
                data-tip
                data-for="emailRelatorioTip"
                style={{ fontSize: '10pt' }}
              />
              <ReactTooltip id="emailRelatorioTip" effect="solid">
                <span>Para adicionar mais de um e-mail separe os endereços por Ponto e Vírgula ( ; )</span>
              </ReactTooltip>
            </div>
            <InputText
              maxLength={100}
              value={dadosSalvar.emailRelatorioPev}
              onChange={(e) => onInputChange(e, 'emailRelatorioPev')}
            />
          </div>

          <div className="p-sm-12 p-md-6 p-lg-6">
            <label htmlFor="distanciaResiduos">Distância dos Resíduos</label>
            <InputNumber
              max={1000000000000}
              id="valor"
              mode="decimal"
              locale="pt-BR"
              value={dadosSalvar.distanciaResiduos}
              onChange={(e) => onInputChange(e, 'distanciaResiduos')}
            />
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ fontWeight: 'bold' }}>SMS</p>

            <div className="p-grid p-fluid">
              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="urlServicoSms">URL do Serviço de SMS</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.urlServicoSms}
                  onChange={(e) => onInputChange(e, 'urlServicoSms')}
                />
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="keyServicoSms">Chave do Serviço de SMS</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.keyServicoSms}
                  onChange={(e) => onInputChange(e, 'keyServicoSms')}
                />
              </div>
            </div>
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ fontWeight: 'bold' }}>Email</p>

            <div className="p-grid">
              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="emailUsername">Login</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.emailUsername}
                  onChange={(e) => onInputChange(e, 'emailUsername')}
                />
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="emailPassword">Senha</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.emailPassword}
                  onChange={(e) => onInputChange(e, 'emailPassword')}
                />
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="emailHost">Host</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.emailHost}
                  onChange={(e) => onInputChange(e, 'emailHost')}
                />
              </div>

              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="emailPort">Porta</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.emailPort}
                  onChange={(e) => onInputChange(e, 'emailPort')}
                />
              </div>
            </div>
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ fontWeight: 'bold' }}>Google</p>
            <div className="p-grid">
              <div className="p-sm-12 p-md-6 p-lg-6">
                <label htmlFor="chaveApiGeocoding">Chave da Api</label>
                <InputText
                  maxLength={50}
                  value={dadosSalvar.chaveApiGeocoding}
                  onChange={(e) => onInputChange(e, 'chaveApiGeocoding')}
                />
              </div>
            </div>
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ fontWeight: 'bold' }}>Ouvidoria</p>
            <div className="p-grid">
              <div className="p-sm-12 p-md-2 p-lg-2">
                <ToggleButton
                  onLabel="Ativo"
                  offLabel="Inativo"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                  checked={flagOuvidoria}
                  onChange={(e) => setFlagOuvidoria(e.value)}
                />
              </div>
            </div>
          </div>

          <div className="p-col-12 p-md-12" style={{ textAlign: 'end' }}>
            <Button
              disabled={salvando}
              style={{ width: 'auto' }}
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              onClick={botaoSalvar}
            />
          </div>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

export default Parametros;
