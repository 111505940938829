/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import BasicoService from '../../utils/services/basicoService';

import UsuarioService from '../../utils/services/usuarioService';

const UsuarioCore = (props) => {
  let permissoes: any;

  const emptyUsuarioKC = {
    id: undefined,
    username: '',
    firstName: '',
    lastName: '',
    cpf: '',
    email: '',
    pass: '',
    temporaryPass: false,
    genre: '',
    roleGroups: [{}],
    status: 'ATIVO',
    confirmSenha: '',
    idPontoColeta: '',
  };

  const emptyUsuario5R = {
    id: undefined,
    nome: '',
    idPontoColeta: '',
    idUsuarioKeyCloak: '',
    idUsuario5R: '',
  };

  const opcoesGenero = [
    { label: 'Masculino', value: 'M' },
    { label: 'Feminino', value: 'F' },
  ];

  const basicoService = BasicoService();
  const usuarioService = UsuarioService();

  const [salvando, setSalvando] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [usuarioDialog, setUsuarioDialog] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);
  const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);

  const toast = useRef<any>();
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [opcoesGrupos, setOpcoesGrupos] = useState<any[]>([]);
  const [autoCompletePontoColetaSugestoes, setAutoCompletePontoColetaSugestoes] = useState<any>(null);
  const [autoCompletePontoColetaSelecionado, setAutoCompletePontoColetaSelecionado] = useState<any>(null);

  const [usuarioKC, setUsuarioKC] = useState(emptyUsuarioKC);
  const [usuario5R, setUsuario5R] = useState(emptyUsuario5R);

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: texto,
      life: 5000,
    });
  };

  const consultarGrupos = () => {
    const opcoes: any = [];
    usuarioService.get('/v1/rolegroups').then((data) => {
      if (data && data[0] && data[0].id) {
        for (let i = 0; i < data.length; i += 1) {
          opcoes.push(data[i].name);
        }
        opcoes.sort();
        setOpcoesGrupos(opcoes);
      } else {
        mostrarToast('error', 'Erro!', `Ocorreu um erro ao consultar os grupos de usuarios.`);
      }
    });
  };

  const consultarUsuarios = () => {
    usuarioService.get('/v1/users').then((data) => {
      if (data && data[0] && data[0].id) {
        setUsuarios(data);
        setCarregandoDados(false);
      } else {
        setUsuarios([]);
        setCarregandoDados(false);
      }
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    for (let i = 0; i < permissoes.length; i += 1) {
      if (permissoes[i] === '5R_CON_SIS_USU') {
        temPermissao = true;
        consultarGrupos();
        consultarUsuarios();
      }
      if (i + 1 === permissoes.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const openNew = () => {
    const x = emptyUsuarioKC;
    x.roleGroups.splice(0, 1);
    setUsuarioKC(x);

    setSubmitted(false);
    setUsuarioDialog(true);
  };

  const hideDialog = () => {
    consultarGrupos();
    setSalvando(false);
    setSubmitted(false);
    setUsuarioDialog(false);
    setUsuario5R(emptyUsuario5R);
    setAutoCompletePontoColetaSelecionado(null);
  };

  const acaoAutoCompletePontoColetaPesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/pontos-coleta/consulta?descricao=${event.query.trim()}&size=999999`).then(
        (data) => {
          if (data && data[0] && data[0].id) {
            for (let i = 0; i < data.length; i += 1) {
              _dadosSugestoes.push(data[i]);
            }
            setAutoCompletePontoColetaSugestoes(_dadosSugestoes);
          } else {
            _dadosSugestoes = [];
            setAutoCompletePontoColetaSugestoes(_dadosSugestoes);
          }
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompletePontoColetaSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteSelecionar = (dadosEvento) => {
    usuario5R.idPontoColeta = dadosEvento.id;
  };

  const acaoAutoCompleteLimpar = (dados, campo) => {
    if (dados === '' || dados === null) {
      if (campo === 'pontoColeta') {
        usuario5R.idPontoColeta = '';
        setAutoCompletePontoColetaSelecionado(dados);
      }
    } else if (campo === 'pontoColeta') {
      setAutoCompletePontoColetaSelecionado(dados);
    }
  };

  const editUsuario = (usuarioEditar: any) => {
    usuarioEditar.roleGroups = [];
    setUsuarioKC(usuarioEditar);

    if (usuarioEditar.id) {
      usuarioService.get(`/v1/users/${usuarioEditar.id}/consulta-edicao`).then((data) => {
        if (data && data.id) {
          const retornoUser = data;

          retornoUser.pass = '';
          setUsuarioKC(retornoUser);

          usuarioService.get5r(`/v1/usuarios/consulta-kc?idKeycloak=${retornoUser.keycloakUserId}`).then((data5r) => {
            if (data5r && data5r.id) {
              usuario5R.idUsuario5R = data5r.id;
              if (data5r.pontoColeta) {
                usuario5R.idPontoColeta = data5r.pontoColeta.id;
                usuarioKC.idPontoColeta = data5r.pontoColeta.id;
              }

              setUsuario5R(usuario5R);
              setAutoCompletePontoColetaSelecionado(data5r.pontoColeta);
            } else {
              mostrarToast('error', 'Erro!', ` Ocorreu um erro ao consultar os dados do keycloak.`);
            }
          });

          for (let i = 0; i < retornoUser.roleGroups.length; i += 1) {
            for (let j = 0; j < opcoesGrupos.length; j += 1) {
              if (retornoUser.roleGroups[i] === opcoesGrupos[j]) {
                opcoesGrupos.splice(j, 1);
              }
            }
          }
          const _opcoesGrupos = [...opcoesGrupos];
          setOpcoesGrupos(_opcoesGrupos);
        } else {
          mostrarToast('error', 'Erro!', ` Ocorreu um erro ao consultar os dados do usuário para edição.`);
        }
      });
    }

    setUsuarioDialog(true);
  };

  // const confirmDeleteUsuario = (usuarioKC) => {
  //   setUsuarioKC(usuarioKC);
  //   setDeleteUsuarioDialog(true);
  // };

  const hideDeleteUsuarioDialog = () => {
    setDeleteUsuarioDialog(false);
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _usuario = { ...usuarioKC };
    _usuario[`${dados}`] = val;
    setUsuarioKC(_usuario);
  };

  const addGrupoUsuarios = (dados) => {
    usuarioKC.roleGroups.push(dados);
    for (let i = 0; i < opcoesGrupos.length; i += 1) {
      if (dados === opcoesGrupos[i]) {
        opcoesGrupos.splice(i, 1);
      }
    }
    const _usuario = { ...usuarioKC };
    const _opcoesGrupos = [...opcoesGrupos];
    _usuario.roleGroups.sort();
    setUsuarioKC(_usuario);
    setOpcoesGrupos(_opcoesGrupos);
  };

  const removeGrupoUsuarios = (dados) => {
    opcoesGrupos.push(dados);
    for (let i = 0; i < usuarioKC.roleGroups.length; i += 1) {
      if (dados === usuarioKC.roleGroups[i]) {
        usuarioKC.roleGroups.splice(i, 1);
      }
    }
    const _usuario = { ...usuarioKC };
    const _opcoesGrupos = [...opcoesGrupos];
    _opcoesGrupos.sort();
    setUsuarioKC(_usuario);
    setOpcoesGrupos(_opcoesGrupos);
  };

  const deleteUsuario = () => {
    // usuarioService.deleteTiposProtocolo(usuarioKC.id).then(() => {
    //   mostrarToast('info', 'Aviso!', 'Tipo de Protocolo deletado.')
    //   consultarUsuarios();
    // });
    // setDeleteUsuarioDialog(false);
    // setUsuarioKC(emptyUsuarioKC);
  };

  const requisicaoEditarUsuario5r = (resposta) => {
    if (resposta) {
      let user5r = {};
      if (usuarioKC.idPontoColeta) {
        user5r = {
          nome: `${resposta.firstName} ${resposta.lastName}`,
          idPontoColeta: usuarioKC.idPontoColeta,
          idUsuarioKeyCloak: resposta.keycloakUserId,
        };
      } else {
        user5r = {
          nome: `${resposta.firstName} ${resposta.lastName}`,
          idPontoColeta: null,
          idUsuarioKeyCloak: resposta.keycloakUserId,
        };
      }

      usuarioService.put5r(`/v1/usuarios/${usuario5R.idUsuario5R}`, user5r).then(() => {
        mostrarToast('success', 'Sucesso!', 'Usuário editado.');
        hideDialog();
        consultarGrupos();
        consultarUsuarios();
      });
    } else {
      mostrarToast('error', 'Erro!', `Ocorreu um erro ao tentar cadastrar o usuário: ${resposta.error}`);
      hideDialog();
      consultarGrupos();
      consultarUsuarios();
    }
  };

  const saveUsuario = () => {
    usuarioKC.idPontoColeta = usuario5R.idPontoColeta;
    setSubmitted(true);

    if (usuarioKC.id === undefined) {
      if (
        usuarioKC.firstName.trim() &&
        usuarioKC.lastName.trim() &&
        usuarioKC.username.trim() &&
        usuarioKC.email.trim() &&
        usuarioKC.cpf.trim() &&
        usuarioKC.genre.trim() &&
        usuarioKC.pass.trim() &&
        usuarioKC.confirmSenha.trim() &&
        usuarioKC.roleGroups[0]
      ) {
        if (usuarioKC.pass.trim().length >= 6 && usuarioKC.pass.trim() === usuarioKC.confirmSenha.trim()) {
          setSalvando(true);
          usuarioService.post('/v1/users', usuarioKC).then((resp) => {
            if (resp && resp.id) {
              let user5r = {};
              if (usuarioKC.idPontoColeta) {
                user5r = {
                  nome: `${usuarioKC.firstName} ${usuarioKC.lastName}`,
                  idPontoColeta: usuarioKC.idPontoColeta,
                  idUsuarioKeyCloak: resp.id,
                };
              } else {
                user5r = {
                  nome: `${usuarioKC.firstName} ${usuarioKC.lastName}`,
                  idPontoColeta: null,
                  idUsuarioKeyCloak: resp.id,
                };
              }

              usuarioService.post5r('/v1/usuarios', user5r).then(() => {
                mostrarToast('success', 'Sucesso!', 'Usuário criado.');
                hideDialog();
                consultarGrupos();
                consultarUsuarios();
              });
            } else {
              mostrarToast('error', 'Erro!', `Ocorreu um erro ao tentar cadastrar o usuário: ${resp.mensagem}`);
              hideDialog();
              consultarGrupos();
              consultarUsuarios();
            }
          });
        }
      }
    } else {
      setSalvando(true);
      if (
        usuarioKC.firstName.trim() &&
        usuarioKC.lastName.trim() &&
        usuarioKC.username.trim() &&
        usuarioKC.email.trim() &&
        usuarioKC.cpf.trim() &&
        usuarioKC.genre.trim() &&
        usuarioKC.roleGroups[0]
      ) {
        if (usuarioKC.pass && usuarioKC.pass.length >= 6 && usuarioKC.pass === usuarioKC.confirmSenha) {
          usuarioService.put('/v1/users', usuarioKC).then((resp) => {
            requisicaoEditarUsuario5r(resp);
          });
        } else if (!usuarioKC.pass) {
          usuarioService.put('/v1/users', usuarioKC).then((resp) => {
            requisicaoEditarUsuario5r(resp);
          });
        }
      } else {
        setSalvando(false);
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
          onClick={() => editUsuario(rowData)}
        />
        {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-outlined p-button-danger" tooltip="Excluir" tooltipOptions={{ position: 'top' }} onClick={() => confirmDeleteUsuario(rowData)} /> */}
      </>
    );
  };

  const deleteUsuarioDialogFooter = (
    <>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuarioDialog} />
      <Button label="Sim" icon="pi pi-check" className="p-button-danger" onClick={deleteUsuario} />
    </>
  );

  const usuarioDialogFooter = (
    <>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        label="Salvar"
        disabled={salvando}
        icon="pi pi-check"
        className="p-button-success"
        onClick={saveUsuario}
      />
    </>
  );

  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Usuários</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNew} />
    </div>
  );

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div style={{ width: '-webkit-fill-available' }}>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={usuarios}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={header}
            >
              <Column field="firstName" header="Nome" />
              <Column field="lastName" header="Sobrenome" />
              <Column field="username" header="Usuário de Acesso" />
              <Column field="email" header="E-mail" />
              <Column bodyStyle={{ textAlign: 'end' }} body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={usuarioDialog}
        style={{ width: '900px' }}
        header="Cadastro de Usuário"
        modal
        className="p-fluid"
        footer={usuarioDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-fluid p-grid" style={{ padding: '10px' }}>
          <div className="p-sm-12 p-md-5 p-lg-5">
            <label htmlFor="firstName">Nome</label>
            <InputText
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="firstName"
              value={usuarioKC.firstName}
              onChange={(e) => onInputChange(e, 'firstName')}
              required
              autoFocus
              className={classNames({
                'p-invalid': submitted && !usuarioKC.firstName,
              })}
            />
            {submitted && !usuarioKC.firstName && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-5 p-lg-5">
            <label htmlFor="lastName">Sobrenome</label>
            <InputText
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="lastName"
              value={usuarioKC.lastName}
              onChange={(e) => onInputChange(e, 'lastName')}
              required
              className={classNames({
                'p-invalid': submitted && !usuarioKC.lastName,
              })}
            />
            {submitted && !usuarioKC.lastName && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-2 p-lg-2">
            <label htmlFor="genre">Gênero</label>
            <Dropdown
              style={{ marginTop: '5px' }}
              value={usuarioKC.genre}
              options={opcoesGenero}
              onChange={(e) => onInputChange(e, 'genre')}
              optionLabel="label"
              required
              className={classNames({
                'p-invalid': submitted && !usuarioKC.genre,
              })}
            />
            {submitted && !usuarioKC.genre && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-5 p-lg-5">
            <label htmlFor="username">Usuário de Acesso</label>
            <InputText
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="username"
              value={usuarioKC.username}
              onChange={(e) => onInputChange(e, 'username')}
              required
              className={classNames({
                'p-invalid': submitted && !usuarioKC.username,
              })}
            />
            {submitted && !usuarioKC.username && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-5 p-lg-5">
            <label htmlFor="email">E-mail</label>
            <InputText
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="email"
              value={usuarioKC.email}
              onChange={(e) => onInputChange(e, 'email')}
              required
              className={classNames({
                'p-invalid': submitted && !usuarioKC.email,
              })}
            />
            {submitted && !usuarioKC.email && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-2 p-lg-2">
            <label htmlFor="cpf">CPF</label>
            <InputMask
              mask="999.999.999-99"
              unmask
              style={{ marginTop: '5px' }}
              id="cpf"
              value={usuarioKC.cpf}
              onChange={(e) => onInputChange(e, 'cpf')}
              required
              className={classNames({
                'p-invalid': submitted && !usuarioKC.cpf,
              })}
            />
            {submitted && !usuarioKC.cpf && <small className="p-error">Campo obrigatório.</small>}
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="pontoColeta">Ponto de Coleta</label>
            <AutoComplete
              value={autoCompletePontoColetaSelecionado}
              suggestions={autoCompletePontoColetaSugestoes}
              completeMethod={acaoAutoCompletePontoColetaPesquisa}
              field="descricao"
              minLength={3}
              onSelect={(e) => acaoAutoCompleteSelecionar(e.value)}
              onChange={(e) => acaoAutoCompleteLimpar(e.value, 'pontoColeta')}
            />
          </div>

          <div className="p-sm-12 p-md-6 p-lg-6">
            {!usuarioKC.id && <label htmlFor="pass">Senha</label>}
            {usuarioKC.id && <label htmlFor="pass">Nova Senha</label>}
            <Password
              feedback={false}
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="pass"
              value={usuarioKC.pass}
              onChange={(e) => onInputChange(e, 'pass')}
              required
              className={classNames({
                'p-invalid':
                  (submitted && !usuarioKC.id && usuarioKC.pass.length < 6) ||
                  (submitted &&
                    usuarioKC.id &&
                    usuarioKC.pass &&
                    usuarioKC.pass.length > 0 &&
                    usuarioKC.pass.length < 6),
              })}
            />
            {((submitted && !usuarioKC.id && usuarioKC.pass.length < 6) ||
              (submitted &&
                usuarioKC.id &&
                usuarioKC.pass &&
                usuarioKC.pass.length > 0 &&
                usuarioKC.pass.length < 6)) && <small className="p-error">Mínimo de 6 caracteres.</small>}
          </div>

          <div className="p-sm-12 p-md-6 p-lg-6">
            <label htmlFor="confirmSenha">Confirmar Senha</label>
            <Password
              feedback={false}
              maxLength={50}
              style={{ marginTop: '5px' }}
              id="confirmSenha"
              value={usuarioKC.confirmSenha}
              onChange={(e) => onInputChange(e, 'confirmSenha')}
              required
              className={classNames({
                'p-invalid':
                  (submitted && !usuarioKC.id && usuarioKC.pass !== usuarioKC.confirmSenha) ||
                  (submitted &&
                    usuarioKC.pass &&
                    usuarioKC.pass.length > 0 &&
                    usuarioKC.pass !== usuarioKC.confirmSenha),
              })}
            />
            {submitted && usuarioKC.pass && usuarioKC.pass.length > 0 && usuarioKC.pass !== usuarioKC.confirmSenha && (
              <small className="p-error">Senhas Diferentes.</small>
            )}
          </div>
        </div>

        <div className="p-fluid p-grid">
          <div className="p-col-12 p-md-12">
            <p style={{ margin: '0', fontWeight: 'bold' }}>Grupos de Usuários</p>
            {submitted && !usuarioKC.roleGroups[0] && <small className="p-error">Selecione ao menos um grupo.</small>}
          </div>
          <div
            style={{
              border: '1px solid #e6e6e6',
              borderRadius: '5px',
              padding: '10px',
              margin: '0 auto',
            }}
            className="p-sm-12 p-md-5 p-lg-5"
          >
            <div>
              <p style={{ margin: '0', fontWeight: 'bold' }}>Grupos Disponíveis</p>
            </div>
            {opcoesGrupos.map((grupo) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  placeItems: 'center',
                }}
              >
                <p>{grupo}</p>
                <Button
                  style={{ marginRight: '15px', color: 'green' }}
                  icon="pi pi-plus"
                  className="p-button-rounded p-button-outlined"
                  tooltip="Adicionar"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => addGrupoUsuarios(grupo)}
                />
              </div>
            ))}
          </div>
          <div
            style={{
              border: '1px solid #e6e6e6',
              borderRadius: '5px',
              padding: '10px',
              margin: '0 auto',
            }}
            className="p-sm-12 p-md-5 p-lg-5"
          >
            <div>
              <p style={{ margin: '0', fontWeight: 'bold' }}>Grupos Adicionados</p>
            </div>
            {usuarioKC.roleGroups.map((grupo) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  placeItems: 'center',
                }}
              >
                <p>{grupo}</p>
                <Button
                  style={{ marginRight: '15px', color: 'red' }}
                  icon="pi pi-times"
                  className="p-button-rounded p-button-outlined"
                  tooltip="Remover"
                  tooltipOptions={{ position: 'top' }}
                  onClick={() => removeGrupoUsuarios(grupo)}
                />
              </div>
            ))}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={deleteUsuarioDialog}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteUsuarioDialogFooter}
        onHide={hideDeleteUsuarioDialog}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {usuarioKC && (
            <span>
              Deseja remover esse tipo de protocolo <b>{usuarioKC.firstName}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default UsuarioCore;
