import React, { useRef } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Toast } from 'primereact/toast';
import { AuthPage } from './pages/AuthPage';

import Aside from './components/aside/Aside';
import Navbar from './components/navbar/Navbar';

import Obras from './pages/5r/basico/ObrasPage';
import Escolas from './pages/5r/basico/EscolasPage';
import UsuariosCore from './pages/core/UsuariosCore';
import Empresas from './pages/5r/basico/EmpresasPage';
import TiposObra from './pages/5r/basico/TiposObraPage';
import Parametros from './pages/5r/basico/ParametrosPage';
import Ocorrencias from './pages/5r/basico/OcorrenciasPage';
import TrocarSenha from './pages/5r/basico/TrocarSenhaPage';
import Protocolos from './pages/5r/ouvidoria/ProtocolosPage';
import PontosColeta from './pages/5r/basico/PontosColetaPage';
import TiposResiduo from './pages/5r/basico/TiposResiduoPage';
import UnidadesSaude from './pages/5r/basico/UnidadesSaudePage';
import TiposProtocolo from './pages/5r/ouvidoria/TiposProtocolo';
import ColetasSeletivas from './pages/5r/basico/ColetasSeletivasPage';
import ManutencaoProtocolos from './pages/5r/ouvidoria/ManutencaoProtocolo';
import PontosColetaValor from './pages/5r/basico/PontosColetaValorPage';
import ResiduosContribuinte from './pages/5r/basico/ResiduosContribuintePage';
import Grupos from './pages/5r/basico/GruposPage';

// import TiposResiduoPage from './pages/5r/basico/TiposResiduoPage/index';
import ExamplePage from './pages/ExamplePage';

export const Routes: React.FC = () => {
  const toast = useRef<Toast>(null);
  const { keycloak, initialized } = useKeycloak();

  const showSuccess = () => {
    if (toast.current) {
      toast.current.show({ severity: 'success', summary: 'Success Message', detail: 'Message Content', life: 5000 });
    }
  };

  if (!initialized) {
    return <div />;
    // return <ExamplePage />;
  }

  return (
    <BrowserRouter>
      <Switch>
        {!keycloak.authenticated ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/" />
        )}
      </Switch>
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        <Aside />
        <Navbar />
        <div style={{ width: '100%', height: '100%' }}>
          <Switch>
            <>
              <div style={{ paddingTop: '40px', height: '100%' }}>
                <Route exact path="/core/usuarios" component={UsuariosCore} />
                <Route exact path="/core/parametros" component={Parametros} />
                <Route exact path="/core/ocorrencias" component={Ocorrencias} />

                <Route exact path="/5r/basico/trocar-senha" component={TrocarSenha} />
                <Route exact path="/5r/basico/grupos" component={Grupos} />

                <Route exact path="/5r/basico/obras" component={Obras} />
                <Route exact path="/5r/basico/escolas" component={Escolas} />
                <Route exact path="/5r/basico/empresas" component={Empresas} />
                <Route exact path="/5r/basico/tipos-obra" component={TiposObra} />
                <Route exact path="/5r/basico/pontos-coleta" component={PontosColeta} />
                <Route exact path="/5r/basico/pontos-coleta-valor" component={PontosColetaValor} />
                <Route exact path="/5r/basico/residuos-contribuinte" component={ResiduosContribuinte} />
                <Route exact path="/5r/basico/tipos-residuo" component={TiposResiduo} />
                <Route exact path="/5r/basico/unidades-saude" component={UnidadesSaude} />
                <Route exact path="/5r/basico/coletas-seletivas" component={ColetasSeletivas} />

                <Route exact path="/5r/ouvidoria/protocolos" component={Protocolos} />
                <Route exact path="/5r/ouvidoria/tipos-protocolo" component={TiposProtocolo} />
                <Route exact path="/5r/ouvidoria/manutencao-protocolos" component={ManutencaoProtocolos} />

                <Route exact path="/example-page" component={ExamplePage} />
              </div>
            </>
          </Switch>
        </div>
        <Toast ref={toast} />
      </div>
    </BrowserRouter>
  );
};
