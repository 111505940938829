/* TODO: see this later */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */

import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';

import { addLocale } from 'primereact/api';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

const PontosColetaValor = (props) => {
  const toast = useRef<any>();
  const basicoService = BasicoService();

  const [carregandoDados, setCarregandoDados] = useState(true);
  const [dadosTabela, setDadosTabela] = useState<any[]>([]);

  const [autoCompleteTipoResiduoSugestoes, setAutoCompleteTipoResiduoSugestoes] = useState<any>(null);
  const [autoCompleteTipoResiduoValorSelecionado, setAutoCompleteTipoResiduoValorSelecionado] = useState<any>(null);

  const [autoCompletePontoColetaSugestoes, setAutoCompletePontoColetaSugestoes] = useState<any>(null);
  const [autoCompletePontoColetaValorSelecionado, setAutoCompletePontoColetaValorSelecionado] = useState<any>(null);

  const [periodoBusca, setPeriodoBusca] = useState<Date | Date[]>();

  let idTipoResiduo = '';
  let idPontoColeta = '';

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_COL_CAD_PC') {
        temPermissao = true;
        setCarregandoDados(false);
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  addLocale('pt-br', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar',
  });

  const acaoAutoCompleteTipoResiduoPesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/tipos-residuo?descricao=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompleteTipoResiduoSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompleteTipoResiduoSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompletePontoColetaPesquisa = (event: { query: string }) => {
    setTimeout(() => {
      let _dadosSugestoes: any = [];
      basicoService.get(`/v1/pontos-coleta/consulta?descricao=${event.query.trim()}`).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _dadosSugestoes.push(data[i]);
          }
          setAutoCompletePontoColetaSugestoes(_dadosSugestoes);
        },
        (error) => {
          _dadosSugestoes = [];
          setAutoCompletePontoColetaSugestoes(_dadosSugestoes);
        },
      );
    }, 250);
  };

  const acaoAutoCompleteSelecionar = (dadosEvento, campo) => {
    if (campo === 'tipo-residuo') idTipoResiduo = dadosEvento.id;
    if (campo === 'ponto-coleta') idPontoColeta = dadosEvento.id;
  };

  const acaoAutoCompleteLimpar = (dados, campo) => {
    if (dados === '' || dados === null) {
      if (campo === 'tipo-residuo') {
        idTipoResiduo = '';
        setAutoCompleteTipoResiduoValorSelecionado(dados);
      } else if (campo === 'ponto-coleta') {
        idPontoColeta = '';
        setAutoCompletePontoColetaValorSelecionado(dados);
      }
    } else if (campo === 'tipo-residuo') {
      setAutoCompleteTipoResiduoValorSelecionado(dados);
    } else if (campo === 'ponto-coleta') {
      setAutoCompletePontoColetaValorSelecionado(dados);
    }
  };

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const formatarDataParametro = (d) => {
    if (d) {
      const data = new Date(d);
      if (data) {
        let dia = data.getDate().toString();
        if (dia.length === 1) dia = `0${dia}`;
        let mes = (data.getMonth() + 1).toString();
        if (mes.length === 1) mes = `0${mes}`;
        const ano = data.getFullYear();

        return `${ano}-${mes}-${dia}` ?? '';
      }
    }

    return null;
  };

  const acaoBuscarPontosColeta = async () => {
    if (!periodoBusca) {
      mostrarToast('error', 'Erro!', 'O período é obrigatório para a pesquisa');
    } else {
      let dataInicial: Date = new Date();
      let dataFinal: Date = new Date();

      let dataInicialParametro;
      let dataFinalParametro;

      if (periodoBusca instanceof Array) {
        [dataInicial, dataFinal] = periodoBusca;
      }

      if (dataInicial) dataInicialParametro = formatarDataParametro(dataInicial);
      if (dataFinal) dataFinalParametro = formatarDataParametro(dataFinal);
      else dataFinalParametro = dataInicialParametro;

      let tipoResiduo = null;
      if (autoCompleteTipoResiduoValorSelecionado) {
        tipoResiduo = autoCompleteTipoResiduoValorSelecionado.descricao;
      }

      let pontoColeta = null;
      if (autoCompletePontoColetaValorSelecionado) {
        pontoColeta = autoCompletePontoColetaValorSelecionado.id;
      }

      setCarregandoDados(true);
      await basicoService
        .get(
          `/v1/residuos/consulta-ponto-coleta?dataInicial=${dataInicialParametro}&dataFinal=${dataFinalParametro}${
            tipoResiduo ? `&tipoResiduo=${tipoResiduo}` : ''
          }${pontoColeta ? `&idPontoColeta=${pontoColeta}` : ''}`,
        )
        .then((dadosGet) => {
          setDadosTabela(dadosGet);
        });
    }
    setCarregandoDados(false);
  };

  const formatarData = (d) => {
    if (d) {
      const data = new Date(d.replace('Z', ''));
      if (data) {
        const dia = data.getDate() + 1;
        let diaAjustado = dia.toString();

        if (diaAjustado.length === 1) diaAjustado = `0${diaAjustado}`;
        let mes = (data.getMonth() + 1).toString();
        if (mes.length === 1) mes = `0${mes}`;
        const ano = data.getFullYear();

        return `${diaAjustado}/${mes}/${ano}`;
      }
    }

    return null;
  };

  const formatarPeso = (d: number) => {
    if (d !== null) {
      const peso = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(d);
      return `${peso} Kg`;
    }

    return null;
  };

  const templatePeso = (rowData, campo) => {
    return formatarPeso(rowData[`${campo}`]);
  };

  const formatarValor = (d: number) => {
    if (d !== null) {
      const valor = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(d);
      return `${valor}`;
    }

    return null;
  };

  const templateValor = (rowData, campo) => {
    console.info(`aaa >${rowData[`${campo}`]}`);
    return formatarValor(rowData[`${campo}`]);
  };

  const templateData = (rowData, campo) => {
    return formatarData(rowData[`${campo}`]);
  };

  const cabecalhoTabela = (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <div className="table-header">
          <h5 className="p-m-0">Consulta de Pontos de Coleta por Valor</h5>
        </div>
      </div>
      <div className="p-fluid p-grid p-formgrid">
        <div className="p-field p-sm-12 p-md-5 p-lg-2">
          <label>Período</label>
          <Calendar
            value={periodoBusca}
            onChange={(e) => setPeriodoBusca(e.value)}
            dateFormat="dd/mm/yy"
            selectionMode="range"
            locale="pt-br"
            readOnlyInput
            showButtonBar
          />
        </div>
        <div className="p-field p-sm-12 p-md-5 p-lg-4">
          <label>Ponto de Coleta</label>
          <AutoComplete
            value={autoCompletePontoColetaValorSelecionado}
            suggestions={autoCompletePontoColetaSugestoes}
            completeMethod={acaoAutoCompletePontoColetaPesquisa}
            field="descricao"
            minLength={3}
            onSelect={(e) => acaoAutoCompleteSelecionar(e.value, 'ponto-coleta')}
            onChange={(e) => acaoAutoCompleteLimpar(e.value, 'ponto-coleta')}
          />
        </div>
        <div className="p-field p-sm-12 p-md-5 p-lg-4">
          <label>Tipo Resíduo</label>
          <AutoComplete
            value={autoCompleteTipoResiduoValorSelecionado}
            suggestions={autoCompleteTipoResiduoSugestoes}
            completeMethod={acaoAutoCompleteTipoResiduoPesquisa}
            field="descricao"
            minLength={3}
            onSelect={(e) => acaoAutoCompleteSelecionar(e.value, 'tipo-residuo')}
            onChange={(e) => acaoAutoCompleteLimpar(e.value, 'tipo-residuo')}
          />
        </div>
        <div className="p-field p-sm-12 p-md-2 p-lg-2" style={{ marginTop: '32px' }}>
          <Button
            label="Buscar"
            icon="pi pi-search"
            className="p-button-info p-mr-2"
            onClick={acaoBuscarPontosColeta}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable emptyMessage="Sem dados" value={dadosTabela} resizableColumns header={cabecalhoTabela}>
              <Column field="descricaoPontoColeta" header="Descrição" sortable />
              <Column field="dataColeta" header="Data" body={(rowData) => templateData(rowData, 'dataColeta')} />
              <Column field="descricaoTipoResiduo" header="Tipo Residuo" />
              <Column field="peso" header="Peso" body={(rowData) => templatePeso(rowData, 'peso')} />
              <Column
                field="valorTotalResiduo"
                header="Valor"
                body={(rowData) => templateValor(rowData, 'valorTotalResiduo')}
              />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Toast ref={toast} />
    </div>
  );
};

export default PontosColetaValor;
