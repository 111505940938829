import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { Button } from 'primereact/button';
import { useKeycloak } from '@react-keycloak/web';
import { Link } from 'react-router-dom';
import { AutoComplete } from 'primereact/autocomplete';
import { StyledNavbar } from './styled';
import A11yButton from '../a11yButton';
import { NavbarProps } from './types';
import { Country } from '../../types';
import './Navbar.css';

const Navbar: React.FC<NavbarProps> = () => {
  const inputField = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  let permissoes: any;

  const { keycloak } = useKeycloak();
  const [buscaMenu, setbuscaMenu] = useState(false);
  const [permi_5R_CON_SIS_PAR, setPermi_5R_CON_SIS_PAR] = useState(false);

  const [countries] = useState([]);
  const [menuAberto, setMenuAberto] = useState(false);

  const [selectedCountry1, setSelectedCountry1] = useState<Country[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);

  const [nomeUsuario, setNomeUsuario] = useState<string>();
  const [emailUsuario, setEmailUsuario] = useState<string>();

  useEffect(() => {
    let nome: any;

    const checkIfClickedOutside = (e) => {
      // checka se o click foi fora do componente
      if (menuAberto && inputField.current && !inputField.current.contains(e.target)) {
        setMenuAberto(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    if (permissoes.find((element) => element === '5R_CON_SIS_PAR') !== undefined) {
      setPermi_5R_CON_SIS_PAR(true);
    }

    if (localStorage.getItem('given_name')) {
      nome = localStorage.getItem('given_name');
    } else {
      nome = localStorage.getItem('user');
    }

    const email: any = localStorage.getItem('email');

    setNomeUsuario(nome.replace(/['"]+/g, ''));
    setEmailUsuario(email.replace(/['"]+/g, ''));

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuAberto]);

  const searchCountry = (event) => {
    setTimeout(() => {
      let filtered;
      if (!event.query.trim().length) {
        filtered = [...countries];
      } else {
        filtered = countries.filter((country: Country) => {
          return country.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }

      setFilteredCountries(filtered);
    }, 250);
  };

  const showComponent = () => {
    setbuscaMenu(!buscaMenu);
  };

  const logout = () => {
    localStorage.clear();
    keycloak.logout();
  };

  return (
    <StyledNavbar>
      {buscaMenu && (
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <AutoComplete
            className="autoCompleteNavbar"
            value={selectedCountry1}
            suggestions={filteredCountries}
            completeMethod={searchCountry}
            field="name"
            onChange={(e) => setSelectedCountry1(e.value)}
            placeholder="Pesquisar..."
          />
        </span>
      )}
      <div>
        <div className="icon-header">
          <Button
            onClick={showComponent}
            style={{ fontSize: '10px' }}
            icon="pi pi-search"
            className="p-button-lg p-button-rounded p-button-text"
          />
        </div>

        <div>
          <Button icon="pi pi-bell" className="p-button-lg p-button-rounded p-button-text" />
        </div>

        <div>
          <Button icon="pi pi-cog" className="p-button-lg p-button-rounded p-button-text" />
        </div>
      </div>

      <div ref={inputField}>
        <div>
          <Button
            onClick={() => setMenuAberto((oldState) => !oldState)}
            style={{ display: 'flex', height: '50px' }}
            className="p-button-text"
          >
            <Avatar size="40" facebookId="100008343750912" />
          </Button>
        </div>
        {menuAberto && (
          <div className="cardMenu">
            <div style={{ padding: '5px 15px', borderBottom: '1px solid #eff2f5' }}>
              <Avatar size="40" facebookId="100008343750912" />
              <div style={{ display: 'block', paddingLeft: '10px' }}>
                <p style={{ fontWeight: 'bolder', color: '#181d32', margin: 0, fontSize: '15px' }}> {nomeUsuario} </p>
                <p style={{ margin: 0, fontSize: '12px' }}>{emailUsuario}</p>
              </div>
            </div>
            <div className="opcaoMenu" style={{}}>
              <Link className="linkLabel" to="/5r/basico/trocar-senha" onClick={() => setMenuAberto(!menuAberto)}>
                Meu Perfil
              </Link>
              {permi_5R_CON_SIS_PAR && (
                <Link className="linkLabel" to="/core/parametros" onClick={() => setMenuAberto(!menuAberto)}>
                  Configurações
                </Link>
              )}
              <A11yButton
                elementProps={{ style: { borderTop: '1px solid rgb(239, 242, 245)' } }}
                elementType="div"
                className="linkMenu"
                onClick={logout}
              >
                <p>Sair</p>
              </A11yButton>
            </div>
          </div>
        )}
      </div>
    </StyledNavbar>
  );
};

export default Navbar;
