import ReactS3Client from 'react-aws-s3-typescript';

export type S3Regions =
  | 'us-east-2'
  | 'us-east-1'
  | 'us-west-1'
  | 'us-west-2'
  | 'af-south-1'
  | 'ap-east-1'
  | 'ap-south-1'
  | 'ap-northeast-3'
  | 'ap-northeast-2'
  | 'ap-southeast-1'
  | 'ap-southeast-2'
  | 'ap-northeast-1'
  | 'ca-central-1'
  | 'cn-north-1'
  | 'cn-northwest-1'
  | 'eu-central-1'
  | 'eu-west-1'
  | 'eu-west-2'
  | 'eu-south-1'
  | 'eu-west-3'
  | 'eu-north-1'
  | 'sa-east-1'
  | 'me-south-1'
  | 'us-gov-east-1'
  | 'us-gov-west-1';

interface S3ServiceConfig {
  bucketName: string;
  dirName?: string;
  region: S3Regions;
  accessKeyId: string;
  secretAccessKey: string;
  s3Url?: string;
}

export const defaultS3Config: S3ServiceConfig = {
  region: 'sa-east-1',
  bucketName: process.env.REACT_APP_BUCKET_NAME || '',
  dirName: process.env.REACT_APP_DIR_NAME || '',
  accessKeyId: process.env.REACT_APP_ACCESS_KEY || '',
  secretAccessKey: process.env.REACT_APP_ACCESS_ID || '',
};

function S3Service(config: S3ServiceConfig) {
  const S3Client = new ReactS3Client(config);

  const uploadFile = async (filename: string, file: any) => {
    config.dirName = `${process.env.REACT_APP_DIR_NAME}/OCORRENCIA` || '';
    const S3ClientTeste = new ReactS3Client(config);
    const response = await S3ClientTeste.uploadFile(file, filename);
    return response;
  };

  const deleteFile = async (filename) => {
    const response = await S3Client.deleteFile(filename);
    return response;
  };

  const lisFiles = async () => {
    const list = await S3Client.listFiles();
    return list;
  };

  return {
    uploadFile,
    deleteFile,
    lisFiles,
  };
}

export const DefaultS3Service = S3Service(defaultS3Config);

export default S3Service;
