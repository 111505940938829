import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: calc(10px + 15px) 15px 15px 15px;
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 75px;
`;

export const EditForm = styled.form`
  width: 800px;
`;
