import React, { useState, useEffect } from 'react';
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaRecycle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import A11yButton from '../a11yButton';
import logo from '../../../assets/ksi.png';
import { keycloak } from '../../utils/auth';
import 'react-pro-sidebar/dist/css/styles.css';
import './Aside.scss';

const Aside: React.FC = () => {
  let menuPermi = [''];

  const [permi_5R_OUV_PROT_MANUTENC_PROT_GESTOR, setPermi_5R_OUV_PROT_MANUTENC_PROT_GESTOR] = useState(false);
  const [permi_5R_OUV_PROT_MANUTENC_PROT, setPermi_5R_OUV_PROT_MANUTENC_PROT] = useState(false);
  const [permi_5R_OUV_PROT_REGISTR_PROT, setPermi_5R_OUV_PROT_REGISTR_PROT] = useState(false);
  const [permi_5R_OUV_CAD_TIP_PRO, setPermi_5R_OUV_CAD_TIP_PRO] = useState(false);
  const [permi_5R_APP_OBR_TIPOBR, setPermi_5R_APP_OBR_TIPOBR] = useState(false);
  const [permi_uma_authorization, setPermi_uma_authorization] = useState(false);
  const [permi_5R_COL_CAD_TIPPC, setPermi_5R_COL_CAD_TIPPC] = useState(false);
  const [permi_offline_access, setPermi_offline_access] = useState(false);
  const [permi_5R_APP_GER_COL, setPermi_5R_APP_GER_COL] = useState(false);
  const [permi_5R_APP_OBR_OBR, setPermi_5R_APP_OBR_OBR] = useState(false);
  const [permi_5R_CON_SIS_USU, setPermi_5R_CON_SIS_USU] = useState(false);
  const [permi_5R_APP_OBR_EMP, setPermi_5R_APP_OBR_EMP] = useState(false);
  const [permi_5R_CON_SIS_PAR, setPermi_5R_CON_SIS_PAR] = useState(false);
  const [permi_5R_CON_SIS_OCO, setPermi_5R_CON_SIS_OCO] = useState(false);
  const [permi_5R_APP_GER_ESC, setPermi_5R_APP_GER_ESC] = useState(false);
  const [permi_5R_APP_GER_UNS, setPermi_5R_APP_GER_UNS] = useState(false);
  const [permi_5R_COL_CAD_PC, setPermi_5R_COL_CAD_PC] = useState(false);
  const [permi_5R_COL_CON_RES_PC, setPermi_5R_COL_CON_RES_PC] = useState(false);
  const [permi_5R_COL_CON_RES_IMO, setPermi_5R_COL_CON_RES_IMO] = useState(false);
  const [permi_5R_CON_SIS_GR_ROLE, setPermi_5R_CON_SIS_GR_ROLE] = useState(false);
  const [permi_5R_CON_SIS_TRO_SEN, setPermi_5R_CON_SIS_TRO_SEN] = useState(false);
  const [permi_GED_DOWNLOAD, setPermi_GED_DOWNLOAD] = useState(false);
  const [permi_ROLE_MASTER, setPermi_ROLE_MASTER] = useState(false);
  const [permi_GED_UPLOAD, setPermi_GED_UPLOAD] = useState(false);
  const [permi_write_user, setPermi_write_user] = useState(false);
  const [permi_read_user, setPermi_read_user] = useState(false);

  const [menuFechado, setMenuFechado] = useState(false);
  const [continuarFechando, setContinuarFechando] = useState(false);

  const permissoesMenu = () => {
    if (menuPermi.find((element) => element === '5R_OUV_PROT_MANUTENC_PROT_GESTOR') !== undefined) {
      setPermi_5R_OUV_PROT_MANUTENC_PROT_GESTOR(true);
    }
    if (menuPermi.find((element) => element === '5R_OUV_PROT_MANUTENC_PROT') !== undefined) {
      setPermi_5R_OUV_PROT_MANUTENC_PROT(true);
    }
    if (menuPermi.find((element) => element === '5R_OUV_PROT_REGISTR_PROT') !== undefined) {
      setPermi_5R_OUV_PROT_REGISTR_PROT(true);
    }
    if (menuPermi.find((element) => element === '5R_OUV_CAD_TIP_PRO') !== undefined) {
      setPermi_5R_OUV_CAD_TIP_PRO(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_OBR_TIPOBR') !== undefined) {
      setPermi_5R_APP_OBR_TIPOBR(true);
    }
    if (menuPermi.find((element) => element === 'uma_authorization') !== undefined) {
      setPermi_uma_authorization(true);
    }
    if (menuPermi.find((element) => element === '5R_COL_CAD_TIPPC') !== undefined) {
      setPermi_5R_COL_CAD_TIPPC(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_GER_UNS') !== undefined) {
      setPermi_5R_APP_GER_UNS(true);
    }
    if (menuPermi.find((element) => element === 'offline_access') !== undefined) {
      setPermi_offline_access(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_GER_COL') !== undefined) {
      setPermi_5R_APP_GER_COL(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_OBR_OBR') !== undefined) {
      setPermi_5R_APP_OBR_OBR(true);
    }
    if (menuPermi.find((element) => element === '5R_CON_SIS_USU') !== undefined) {
      setPermi_5R_CON_SIS_USU(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_GER_ESC') !== undefined) {
      setPermi_5R_APP_GER_ESC(true);
    }
    if (menuPermi.find((element) => element === '5R_APP_OBR_EMP') !== undefined) {
      setPermi_5R_APP_OBR_EMP(true);
    }
    if (menuPermi.find((element) => element === '5R_CON_SIS_PAR') !== undefined) {
      setPermi_5R_CON_SIS_PAR(true);
    }
    if (menuPermi.find((element) => element === '5R_CON_SIS_OCO') !== undefined) {
      setPermi_5R_CON_SIS_OCO(true);
    }
    if (menuPermi.find((element) => element === '5R_CON_SIS_TRO_SEN') !== undefined) {
      setPermi_5R_CON_SIS_TRO_SEN(true);
    }
    if (menuPermi.find((element) => element === '5R_COL_CAD_PC') !== undefined) {
      setPermi_5R_COL_CAD_PC(true);
    }
    if (menuPermi.find((element) => element === '5R_COL_CON_RES-PC') !== undefined) {
      setPermi_5R_COL_CON_RES_PC(true);
    }
    if (menuPermi.find((element) => element === '5R_COL_CON_RES-IMO') !== undefined) {
      setPermi_5R_COL_CON_RES_IMO(true);
    }
    if (menuPermi.find((element) => element === '5R_CON_SIS_GR-ROLE') !== undefined) {
      setPermi_5R_CON_SIS_GR_ROLE(true);
    }
    if (menuPermi.find((element) => element === 'GED_DOWNLOAD') !== undefined) {
      setPermi_GED_DOWNLOAD(true);
    }
    if (menuPermi.find((element) => element === 'ROLE_MASTER') !== undefined) {
      setPermi_ROLE_MASTER(true);
    }
    if (menuPermi.find((element) => element === 'GED_UPLOAD') !== undefined) {
      setPermi_GED_UPLOAD(true);
    }
    if (menuPermi.find((element) => element === 'write_user') !== undefined) {
      setPermi_write_user(true);
    }
    if (menuPermi.find((element) => element === 'read_user') !== undefined) {
      setPermi_read_user(true);
    }
  };

  const montarTabela = () => {
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwtDecode(tokenJWT);
    localStorage.removeItem('user');
    localStorage.removeItem('given_name');

    menuPermi = decodedHeader.realm_access.roles;

    if (decodedHeader.preferred_username) {
      localStorage.setItem('user', JSON.stringify(decodedHeader.preferred_username));
    } else if (decodedHeader.given_name) {
      localStorage.setItem('given_name', JSON.stringify(decodedHeader.given_name));
    }

    localStorage.setItem('email', JSON.stringify(decodedHeader.email));
    localStorage.setItem('permissoes', JSON.stringify(menuPermi));
    localStorage.setItem('tenant', JSON.stringify(decodedHeader.tenant_name));
    permissoesMenu();
  };

  useEffect(() => {
    montarTabela();
  });

  const abreFechaMenu = () => {
    if (!continuarFechando) {
      setMenuFechado(!menuFechado);
    }
    setContinuarFechando(!continuarFechando);
  };

  const menuMouseEnter = () => {
    setMenuFechado(false);
  };

  const menuMouseLeave = () => {
    if (continuarFechando) {
      setMenuFechado(true);
    }
  };

  return (
    <div onMouseEnter={() => menuMouseEnter()} onMouseLeave={() => menuMouseLeave()} style={{ zIndex: 2 }}>
      <div className="sidebar">
        <div
          style={{
            height: '50px',
            backgroundColor: '#1b1b28',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 10px 0 25px',
          }}
        >
          {!menuFechado && (
            <a href="/">
              <img style={{ maxHeight: '35px' }} src={logo} alt="" />
            </a>
          )}
          <A11yButton elementType="div" onClick={abreFechaMenu}>
            {continuarFechando && (
              <div>
                <span>
                  <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      style={{ fill: '#54547b' }}
                      opacity="0.5"
                      d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
                      fill="black"
                    />
                    <path
                      style={{ fill: '#5c6093' }}
                      d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 15.6343 11.4343Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            )}
            {!continuarFechando && (
              <div>
                <span>
                  <svg
                    style={{ transform: 'rotate(180deg)' }}
                    width="25"
                    height="30"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      style={{ fill: '#54547b' }}
                      opacity="0.5"
                      d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
                      fill="black"
                    />
                    <path
                      style={{ fill: '#5c6093' }}
                      d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 15.6343 11.4343Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
            )}
          </A11yButton>
        </div>
        <ProSidebar collapsed={menuFechado} width="250px">
          <Menu popperArrow={false}>
            {/* Menu Master */}
            <SubMenu className="menuPrimario" title="5R" icon={<FaRecycle />}>
              {/* Menu Primario */}
              {(permi_5R_CON_SIS_USU ||
                permi_5R_CON_SIS_PAR ||
                permi_5R_CON_SIS_OCO ||
                permi_5R_CON_SIS_TRO_SEN ||
                permi_5R_CON_SIS_GR_ROLE) && (
                <SubMenu className="menuSecundario" title="Configurações">
                  {/* Menu Secundario */}
                  <SubMenu className="menuSecundario" title="Sistema">
                    {/* SubMenu */}
                    {permi_5R_CON_SIS_USU && (
                      <MenuItem className="menuSecundario">
                        Usuários <Link to="/core/usuarios" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_CON_SIS_PAR && (
                      <MenuItem className="menuSecundario">
                        Parâmetro <Link to="/core/parametros" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_CON_SIS_OCO && (
                      <MenuItem className="menuSecundario">
                        Ocorrência <Link to="/core/ocorrencias" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_CON_SIS_TRO_SEN && (
                      <MenuItem className="menuSecundario">
                        Trocar Senha <Link to="/5r/basico/trocar-senha" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_CON_SIS_GR_ROLE && (
                      <MenuItem className="menuSecundario">
                        Grupo <Link to="/5r/basico/grupos" />
                      </MenuItem>
                    )}
                  </SubMenu>
                </SubMenu>
              )}

              {/* Menu Primario */}
              {(permi_5R_OUV_CAD_TIP_PRO || permi_5R_OUV_PROT_MANUTENC_PROT || permi_5R_OUV_PROT_REGISTR_PROT) && (
                <SubMenu className="menuSecundario" title="Ouvidoria">
                  {/* Menu Secundario */}
                  {permi_5R_OUV_CAD_TIP_PRO && (
                    <SubMenu className="menuSecundario" title="Cadastro">
                      {/* SubMenu */}
                      {permi_5R_OUV_CAD_TIP_PRO && (
                        <MenuItem className="menuSecundario">
                          Tipos de Protocolo <Link to="/5r/ouvidoria/tipos-protocolo" />
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}

                  {/* Menu Secundario */}
                  {(permi_5R_OUV_PROT_MANUTENC_PROT || permi_5R_OUV_PROT_REGISTR_PROT) && (
                    <SubMenu className="menuSecundario" title="Protocolo">
                      {/* SubMenu */}
                      {permi_5R_OUV_PROT_MANUTENC_PROT && (
                        <MenuItem className="menuSecundario">
                          Manutenção <Link to="/5r/ouvidoria/manutencao-protocolos" />
                        </MenuItem>
                      )}

                      {/* SubMenu */}
                      {permi_5R_OUV_PROT_REGISTR_PROT && (
                        <MenuItem className="menuSecundario">
                          Registro <Link to="/5r/ouvidoria/protocolos" />
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}
                </SubMenu>
              )}

              {/* Menu Primario */}
              {(permi_5R_COL_CAD_PC || permi_5R_COL_CAD_TIPPC) && (
                <SubMenu className="menuSecundario" title="Coleta">
                  {/* Menu Secundario */}
                  <SubMenu className="menuSecundario" title="Cadastro">
                    {/* SubMenu */}
                    {permi_5R_COL_CAD_PC && (
                      <MenuItem className="menuSecundario">
                        Ponto de Coleta <Link to="/5r/basico/pontos-coleta" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_COL_CAD_TIPPC && (
                      <MenuItem className="menuSecundario">
                        Tipo de Residuo <Link to="/5r/basico/tipos-residuo" />
                      </MenuItem>
                    )}
                  </SubMenu>

                  {/* Menu Secundario */}
                  <SubMenu className="menuSecundario" title="Consulta">
                    {/* SubMenu */}
                    {permi_5R_COL_CAD_PC && (
                      <MenuItem className="menuSecundario">
                        Ponto de Coleta Valor <Link to="/5r/basico/pontos-coleta-valor" />
                      </MenuItem>
                    )}

                    {/* SubMenu */}
                    {permi_5R_COL_CON_RES_IMO && (
                      <MenuItem className="menuSecundario">
                        Residuos por Imovel <Link to="/5r/basico/residuos-contribuinte" />
                      </MenuItem>
                    )}
                  </SubMenu>
                </SubMenu>
              )}

              {/* Menu Primario */}
              {(permi_5R_APP_OBR_EMP ||
                permi_5R_APP_OBR_OBR ||
                permi_5R_APP_OBR_TIPOBR ||
                permi_5R_APP_GER_ESC ||
                permi_5R_APP_GER_COL ||
                permi_5R_APP_GER_UNS) && (
                <SubMenu className="menuSecundario" title="Aplicativo Cidadão">
                  {/* Menu Secundario */}
                  {(permi_5R_APP_OBR_EMP || permi_5R_APP_OBR_OBR || permi_5R_APP_OBR_TIPOBR) && (
                    <SubMenu className="menuSecundario" title="Cadastro Obras">
                      {/* SubMenu */}
                      {permi_5R_APP_OBR_EMP && (
                        <MenuItem className="menuSecundario">
                          Empresa <Link to="/5r/basico/empresas" />
                        </MenuItem>
                      )}

                      {/* SubMenu */}
                      {permi_5R_APP_OBR_OBR && (
                        <MenuItem className="menuSecundario">
                          Obra <Link to="/5r/basico/Obras" />
                        </MenuItem>
                      )}

                      {/* SubMenu */}
                      {permi_5R_APP_OBR_TIPOBR && (
                        <MenuItem className="menuSecundario">
                          Tipo de Obra <Link to="/5r/basico/tipos-obra" />
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}

                  {/* Menu Secundario */}
                  {(permi_5R_APP_GER_ESC || permi_5R_APP_GER_COL || permi_5R_APP_GER_UNS) && (
                    <SubMenu className="menuSecundario" title="Cadastro Geral">
                      {/* SubMenu */}
                      {permi_5R_APP_GER_ESC && (
                        <MenuItem className="menuSecundario">
                          Escola <Link to="/5r/basico/escolas" />
                        </MenuItem>
                      )}

                      {/* SubMenu */}
                      {permi_5R_APP_GER_COL && (
                        <MenuItem className="menuSecundario">
                          Coleta Seletiva <Link to="/5r/basico/coletas-seletivas" />
                        </MenuItem>
                      )}

                      {/* SubMenu */}
                      {permi_5R_APP_GER_UNS && (
                        <MenuItem className="menuSecundario">
                          Unidade de Saúde <Link to="/5r/basico/unidades-saude" />
                        </MenuItem>
                      )}
                    </SubMenu>
                  )}
                </SubMenu>
              )}
            </SubMenu>
          </Menu>
        </ProSidebar>
      </div>

      <div>
        <ProSidebar collapsed={menuFechado} width="250px" />
      </div>
    </div>
  );
};

export default Aside;
