import { authHttp } from '../http';

export const USER_DETAILS = '/v1/users/details';

export const getUserByUsername = (payload) => {
  return authHttp
    .get(USER_DETAILS, { params: { username: payload.username } })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default () => ({
  get(url) {
    return authHttp
      .get(process.env.REACT_APP_KSI_URL_USER + url, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  },

  post(url, dados) {
    return authHttp
      .post(process.env.REACT_APP_KSI_URL_USER + url, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  get5r(url) {
    return authHttp
      .get(process.env.REACT_APP_KSI_URL_5R + url, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  },

  post5r(url, dados) {
    return authHttp
      .post(process.env.REACT_APP_KSI_URL_5R + url, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  put5r(url, dados) {
    return authHttp
      .put(process.env.REACT_APP_KSI_URL_5R + url, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  put(url, dados) {
    return authHttp
      .put(process.env.REACT_APP_KSI_URL_USER + url, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  },

  delete(url) {
    return authHttp
      .delete(process.env.REACT_APP_KSI_URL_USER + url)
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        return error;
      });
  },
});
