import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { keycloak } from '../../../utils/auth';
import CoreService from '../../../utils/services/coreService';

const Ocorrencias: React.FC<RouteComponentProps> = (props) => {
  const toast = useRef<Toast>(null);
  const coreService = CoreService();
  const [carregandoDados, setCarregandoDados] = useState(true);
  const [dadosTabela, setDadosTabela] = useState<any[]>([]);

  // eslint-disable-next-line prefer-const
  let [valorPesquisa, setValorPesquisa] = useState('');

  const consultarDadosTabela = (pesquisa) => {
    if (pesquisa) {
      coreService.get(`/v1/ocorrencias/consulta?search=${pesquisa}`).then((dadosGet) => {
        setDadosTabela(dadosGet);
        setCarregandoDados(false);
      });
    } else {
      coreService.get('/v1/ocorrencias/consulta?search=').then((dadosGet) => {
        setDadosTabela(dadosGet);
        setCarregandoDados(false);
      });
    }
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_CON_SIS_OCO') {
        temPermissao = true;
        consultarDadosTabela('');
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const acaoInputDigitar = (e) => {
    const val = e.target ? e.target.value : e.value;

    valorPesquisa = val;
    setValorPesquisa(valorPesquisa);
    if (valorPesquisa.length >= 3) {
      consultarDadosTabela(valorPesquisa);
    }
  };

  const formatarDataHora = (d: number | string | Date) => {
    if (d) {
      const data = new Date(d);
      if (data) {
        const dia = data.getDate();
        const mes = data.getMonth() + 1;
        const ano = data.getFullYear();
        const hora = data.getHours();
        const minutos = data.getMinutes();
        return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
      }
    }

    return '';
  };

  const templateDataRegistro = (rowData) => {
    return formatarDataHora(rowData.data);
  };

  const cabecalhoTabela = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Ocorrências</h5>
      </div>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText maxLength={50} placeholder="Procurar" value={valorPesquisa} onChange={(e) => acaoInputDigitar(e)} />
      </span>
    </div>
  );

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              removableSort
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="data" header="Data de Registro" body={templateDataRegistro} sortable />
              <Column field="descricaoEvento" header="Descrição do Evento" sortable />
              <Column field="origem" header="Fonte do Evento" sortable />
              <Column field="tipo" header="Nível de Criticidade" sortable />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Toast ref={toast} />
    </div>
  );
};

export default Ocorrencias;
