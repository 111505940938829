import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { Toast } from 'primereact/toast';

import ProtocoloService from '../../../utils/services/protocoloService';
import { DefaultS3Service } from '../../../utils/services/s3Service';

import '../../../../index.css';
import 'primeflex/primeflex.css';

export interface ListaItensTipo {
  idArquivoItem: string;
  nomeArquivo: string;
}

export interface ProtocoloTipo {
  idTipoProtocolo: string;
  descricao: string;
  dataFato: string;
  idImovel: string;
  telefone: string;
  celular: string;
  status: string;
  email: string;
  idArquivo?: string;
  listaItens?: Array<ListaItensTipo>;
}

const Protocolos: React.FC<RouteComponentProps> = (props) => {
  const dataAgora = new Date(Date.now());

  let fileUploadRef: any;
  let permissoes: any;

  const protocoloVazio: ProtocoloTipo = {
    dataFato: '',
    idTipoProtocolo: '',
    idImovel: '',
    telefone: '',
    celular: '',
    email: '',
    descricao: '',
    status: 'AGUARDANDO',
  };

  const emptyImagensUpload = [
    {
      name: '',
      type: '',
      webkitRelativePath: '',
      objectURL: Number,
      lastModified: Number,
      lastModifiedDate: Date,
      size: Number,
    },
  ];

  const listaSalvar = {
    modulo: 'OCORRENCIA',
    microServico: 'CINCO_R',
    nomeArquivos: [''],
  };

  const outrosDadosVazio = {
    bairro: '',
    numero: '',
    logradouro: '',
  };

  const toast = useRef<Toast>(null);
  const protocoloService = ProtocoloService();

  const [salvando, setSalvando] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [protocolo, setProtocolo] = useState(protocoloVazio);
  const [outrosDados, setOutrosDados] = useState(outrosDadosVazio);
  const [imagensUpload, setImagensUpload] = useState(emptyImagensUpload);

  const [tiposProtocolo, setTiposProtocolo] = useState<any>([]);
  const [selectedTipoProtocolo, setSelectedTipoProtocolo] = useState<any>(null);

  const [selectedImovel, setSelectedImovel] = useState<any>(null);
  const [filteredImoveis, setFilteredImoveis] = useState<any>(null);

  const [dataFato, setdataFato] = useState<Date | Date[] | undefined>(dataAgora);

  addLocale('br', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      '	Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Sep', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar',
  });

  const consultarTiposProtocolo = () => {
    protocoloService.getTiposProtocolo().then((data) => {
      if (data) {
        setTiposProtocolo(data);
      }
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    for (let i = 0; i < permissoes.length; i += 1) {
      if (permissoes[i] === '5R_OUV_PROT_REGISTR_PROT') {
        temPermissao = true;
        consultarTiposProtocolo();
      }
      if (i + 1 === permissoes.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  const onUpload = () => {
    console.log('onUpload');
  };

  const addImgUpload = (event) => {
    if (event.files[0].size <= 1048576) {
      imagensUpload.push(event.files[0]);
    }
  };

  const removeImgUpload = (event) => {
    const arquivo = event.file;
    for (let i = 0; i < imagensUpload.length; i += 1) {
      if (imagensUpload[i].name === arquivo.name) {
        imagensUpload.splice(i, 1);
      }
    }
  };

  const searchImovel = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredImoveis;
      protocoloService.pesquisarImoveis(event.query.trim()).then(
        (data) => {
          _filteredImoveis = data;
          for (let i = 0; i < data.length; i += 1) {
            _filteredImoveis[i].descAuto = `${_filteredImoveis[i].codigo}- ${_filteredImoveis[i].contribuinte}`;
          }
          setFilteredImoveis(_filteredImoveis);
        },
        (error) => {
          _filteredImoveis = [];
          setFilteredImoveis(_filteredImoveis);
        },
      );
    }, 250);
  };

  const imovelSelecionado = (dadosEvento) => {
    protocoloService.getDadosImovel(dadosEvento.id).then((data) => {
      let _outroDado = { ...outrosDados };
      _outroDado = {
        bairro: data.bairro,
        numero: data.numero,
        logradouro: data.logradouro,
      };
      setOutrosDados(_outroDado);
      protocolo.idImovel = data.id;
    });
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _usuario = { ...protocolo };
    _usuario[`${dados}`] = val;

    setProtocolo(_usuario);
  };

  const botaoSalvar = () => {
    setSubmitted(true);
    const refUploader = fileUploadRef;
    const refEmptyImagens = emptyImagensUpload;

    const yyyy = dataAgora.getFullYear().toString();
    const mm = (dataAgora.getMonth() + 1).toString();
    const dd = dataAgora.getDate().toString();
    const hh = dataAgora.getHours().toString();
    const minute = dataAgora.getMinutes().toString();
    const second = dataAgora.getSeconds().toString();
    const mmChars = mm.split('');
    const ddChars = dd.split('');
    const hhChars = hh.split('');
    const minuteChars = minute.split('');
    const secondChars = second.split('');

    const date = `${yyyy}-${mmChars[1] ? mm : `0${mmChars[0]}`}-${ddChars[1] ? dd : `0${ddChars[0]}`}`;
    const time = `${hhChars[1] ? hh : `0${hhChars[0]}`}:${minuteChars[1] ? minute : `0${minuteChars[0]}`}:${
      secondChars[1] ? second : `0${secondChars[0]}`
    }`;

    protocolo.dataFato = `${date} ${time}`;

    listaSalvar.nomeArquivos = [];
    for (let i = 0; i < imagensUpload.length; i += 1) {
      if (imagensUpload[i].name) {
        listaSalvar.nomeArquivos.push(imagensUpload[i].name);
      }
    }

    if (selectedTipoProtocolo) {
      protocolo.idTipoProtocolo = selectedTipoProtocolo.id;
    }

    if (
      protocolo.dataFato.trim() &&
      protocolo.idTipoProtocolo.trim() &&
      protocolo.idImovel.trim() &&
      (protocolo.telefone.trim() || protocolo.celular.trim() || protocolo.email.trim()) &&
      protocolo.descricao.trim()
    ) {
      setSalvando(true);

      if (imagensUpload.length > 1) {
        protocoloService.postNomeArquivos(listaSalvar).then((dados) => {
          protocolo.idArquivo = dados.idArquivo;
          protocolo.listaItens = dados.listaItens;
          for (let i = 0; i < imagensUpload.length; i += 1) {
            if (imagensUpload[i].name !== '') {
              if (protocolo.listaItens) {
                // eslint-disable-next-line dot-notation
                DefaultS3Service.uploadFile(
                  `${protocolo.idArquivo}_${protocolo.listaItens[i - 1].idArquivoItem}_${
                    protocolo.listaItens[i - 1].nomeArquivo.split('.')[0]
                  }`,
                  imagensUpload[i],
                ).then(() => {
                  if (i + 1 === imagensUpload.length) {
                    protocoloService.postProtocolo(protocolo).then((data) => {
                      mostrarToast(
                        'success',
                        'Sucesso!',
                        `Protocolo gerado com sucesso. Nº do protocolo: ${data.numero}`,
                      );
                      refUploader.clear();
                      setImagensUpload(refEmptyImagens);
                      setProtocolo(protocoloVazio);
                      setOutrosDados(outrosDadosVazio);
                      setSelectedTipoProtocolo(null);
                      setSelectedImovel(null);
                      setSubmitted(false);
                      setSalvando(false);
                    });
                  }
                });
              }
            }
          }
        });
      } else {
        protocoloService.postProtocolo(protocolo).then((dados) => {
          mostrarToast('success', 'Sucesso!', `Protocolo gerado com sucesso. Nº do protocolo: ${dados.numero}`);
          setProtocolo(protocoloVazio);
          setOutrosDados(outrosDadosVazio);
          setSelectedTipoProtocolo(null);
          setSelectedImovel(null);
          setSubmitted(false);
          setSalvando(false);
        });
      }
    }
  };

  const chooseOptions = { label: 'Procurar', icon: 'pi pi-fw pi-plus' };
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent' }}>
        {chooseButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-file p-p-3"
          style={{
            fontSize: '2em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="p-my-3">
          Arraste e solte aqui!
        </span>
      </div>
    );
  };

  return (
    <div>
      <div className="p-fluid p-grid card" style={{ margin: '20px', padding: '0', marginTop: '20px' }}>
        <div
          className="p-col-12 p-md-12"
          style={{
            borderBottom: '1px solid #dbdbdb',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h2 style={{ margin: '15px', fontSize: '20px' }}>Registrar Protocolo</h2>
        </div>

        <div className="p-col-12 p-md-12" style={{ padding: '20px' }}>
          <div className="p-grid">
            <div className="p-sm-12 p-md-6 p-lg-6">
              <label htmlFor="dataFato">Data e Hora</label>
              <Calendar
                dateFormat="dd/mm/yy"
                locale="br"
                value={dataFato}
                onChange={(e) => setdataFato(e.value)}
                showTime
              />
              {submitted && !protocolo.dataFato && <small className="p-error">Campo obrigatório.</small>}
            </div>
          </div>

          <div className="p-grid">
            <div className="p-sm-12 p-md-6 p-lg-6">
              <label htmlFor="selectedTipoProtocolo">Tipo de Protocolo</label>

              <Dropdown
                style={{ marginTop: '5px' }}
                value={selectedTipoProtocolo}
                options={tiposProtocolo}
                onChange={(e) => setSelectedTipoProtocolo(e.value)}
                optionLabel="descricao"
                placeholder="Selecione um tipo"
              />
              {submitted && !selectedTipoProtocolo && <small className="p-error">Campo obrigatório.</small>}
            </div>

            <div className="p-sm-12 p-md-6 p-lg-6">
              <label htmlFor="dataFato">Imóvel</label>
              <AutoComplete
                value={selectedImovel}
                suggestions={filteredImoveis}
                completeMethod={searchImovel}
                tooltip="Contribuinte ou código/endereço do imóvel"
                tooltipOptions={{ position: 'top' }}
                forceSelection
                field="descAuto"
                minLength={3}
                onSelect={(e) => imovelSelecionado(e.value)}
                onChange={(e) => setSelectedImovel(e.value)}
              />
              {submitted && !protocolo.idImovel && <small className="p-error">Campo obrigatório.</small>}
            </div>
          </div>

          <div className="p-grid">
            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="logradouro" style={{ color: '#a6a6a6' }}>
                Logradouro
              </label>
              <InputText disabled value={outrosDados.logradouro} />
            </div>
            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="bairro" style={{ color: '#a6a6a6' }}>
                Bairro
              </label>
              <InputText disabled value={outrosDados.bairro} />
            </div>

            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="numero" style={{ color: '#a6a6a6' }}>
                Número
              </label>
              <InputText disabled value={outrosDados.numero} />
            </div>
          </div>

          <div className="p-col-12 p-md-12" style={{ paddingLeft: 0 }}>
            <p style={{ margin: '0', fontWeight: 'bold' }}>Grupo Contatos</p>
            {submitted && !protocolo.telefone && submitted && !protocolo.celular && submitted && !protocolo.email && (
              <small className="p-error">Preencha ao menos um campo de contato.</small>
            )}
          </div>
          <div className="p-grid">
            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="Telefone">Telefone</label>
              <InputMask
                mask="(99) 9999-9999"
                style={{ marginTop: '5px' }}
                value={protocolo.telefone}
                onChange={(e) => onInputChange(e, 'telefone')}
              />
            </div>

            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="celular">Celular</label>
              <InputMask
                mask="(99) 99999999?9"
                style={{ marginTop: '5px' }}
                value={protocolo.celular}
                onChange={(e) => onInputChange(e, 'celular')}
              />
            </div>
            <div className="p-sm-12 p-md-4 p-lg-4">
              <label htmlFor="email">Email</label>
              <InputText
                maxLength={100}
                style={{ marginTop: '5px' }}
                value={protocolo.email}
                onChange={(e) => onInputChange(e, 'email')}
              />
            </div>
          </div>

          <div className="p-grid">
            <div className="p-sm-12 p-md-12 p-lg-12">
              <label htmlFor="descricao">Descrição</label>
              {submitted && !protocolo.descricao && <small className="p-error">Campo obrigatório.</small>}
              <InputTextarea
                maxLength={2000}
                rows={5}
                value={protocolo.descricao}
                onChange={(e) => onInputChange(e, 'descricao')}
              />
            </div>
          </div>

          <div className="p-grid">
            <div className="p-sm-12 p-md-12 p-lg-12">
              <p style={{ margin: '5px', fontSize: '13px' }}>Upload Arquivo</p>
              <FileUpload
                name="demo[]"
                url="./upload"
                customUpload
                maxFileSize={2000000}
                onUpload={onUpload}
                onSelect={addImgUpload}
                onRemove={removeImgUpload}
                headerTemplate={headerTemplate}
                emptyTemplate={emptyTemplate}
                accept="image/*,.pdf"
                chooseOptions={chooseOptions}
                ref={(el) => {
                  fileUploadRef = el;
                }}
              />
            </div>
          </div>

          <div
            className="p-col-12 p-md-12"
            style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
          >
            <Button
              label="Salvar"
              disabled={salvando}
              icon="pi pi-check"
              className="p-button-success"
              onClick={botaoSalvar}
            />
          </div>
        </div>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

export default Protocolos;
