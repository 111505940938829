import { createSlice } from '@reduxjs/toolkit';

export interface FakeState {
  count: number;
}

const initialState: FakeState = {
  count: 0,
};

const fakeSlice = createSlice({
  name: 'fake',
  initialState,
  reducers: {
    increment(state) {
      state.count += 1;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = fakeSlice;
// Extract and export each action creator by name
export const { increment } = actions;
// Export the reducer, either as a default or named export
export default reducer;
