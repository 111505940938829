import React, { useEffect, useState, useRef } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Slider, SliderValueType } from 'primereact/slider';
import { DataTable, DataTablePageParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Container, Card, Image, EditForm } from './styled';
import { ExamplePageProps, EditDialogProps, Fields, DeleteDialogProps, FilterDialogProps } from './types';
import { useGetDataQuery, FakeServiceGetResponseData } from '../../store/apis/fake';

/**
 * DIALOGS
 */
const EditDialog: React.FC<EditDialogProps> = ({ loading, onConfirm, onHide, show, data }) => {
  // Setup form
  const { handleSubmit, register, formState, setValue, reset } = useForm<Fields>();

  // Registers
  const nameRegister = register('name', { required: { value: true, message: 'Campo obrigatório' } });
  const priceRegister = register('price', { required: { value: true, message: 'Campo obrigatório' } });
  const stockRegister = register('stock', { required: { value: true, message: 'Campo obrigatório' } });

  // Formatted registers
  const priceFormatted = {
    inputRef: priceRegister.ref,
    onBlur: priceRegister.onBlur,
    onValueChange: priceRegister.onChange,
    name: priceRegister.name,
  };
  const stockFormatted = {
    inputRef: stockRegister.ref,
    onBlur: stockRegister.onBlur,
    onValueChange: stockRegister.onChange,
    name: stockRegister.name,
  };

  useEffect(() => {
    // Reset the form everytime the form is rendered
    reset();

    // Setup the fields
    if (data) {
      setValue('name', data.name);
      setValue('price', data.price);
      setValue('stock', data.stock);
    }
  }, [data, show]);

  // Submit handler. OBS: Workaround with PrimeReact, because it does not support ref inputs by default
  const onSubmit: SubmitHandler<Fields> = (formData) => onConfirm(formData, formState.errors);
  const handleClick = () => handleSubmit(onSubmit)();

  // Template for rendering image column
  const renderFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={onHide} />
      <Button label="Salvar" icon="pi pi-check" disabled={loading} className="p-button-success" onClick={handleClick} />
    </div>
  );

  return (
    <Dialog modal dismissableMask footer={renderFooter} header="Cadastro de Exemplo" onHide={onHide} visible={show}>
      <EditForm className="p-fluid p-grid" onSubmit={handleSubmit(onSubmit)}>
        <div className="p-sm-12 p-md-12 p-lg-12">
          <label htmlFor="name">Descrição</label>
          <InputText autoFocus id="name" {...nameRegister} />
          {formState.errors.name && <small className="p-error">{formState.errors.name.message}</small>}
        </div>
        <div className="p-sm-10 p-md-10 p-lg-10">
          <label htmlFor="name">Preço</label>
          <InputNumber id="price" mode="decimal" minFractionDigits={2} maxFractionDigits={2} {...priceFormatted} />
          {formState.errors.price && <small className="p-error">{formState.errors.price.message}</small>}
        </div>
        <div className="p-sm-2 p-md-2 p-lg-2">
          <label htmlFor="stock">Estoque</label>
          <InputNumber id="stock" {...stockFormatted} />
          {formState.errors.stock && <small className="p-error">{formState.errors.stock.message}</small>}
        </div>
      </EditForm>
    </Dialog>
  );
};

const FilterDialog: React.FC<FilterDialogProps> = ({ loading, ...props }) => {
  const [value, setValue] = useState<SliderValueType>([20, 80]);

  // Template for rendering image column
  const renderFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={props.onHide} />
      <Button label="Sim" icon="pi pi-check" disabled={loading} className="p-button-danger" />
    </div>
  );

  return (
    <Dialog
      position="top-right"
      modal
      dismissableMask
      footer={renderFooter}
      header="Opções de filtros!"
      onHide={props.onHide}
      visible={props.show}
      style={{
        position: 'absolute',
        right: '161px',
        top: '150px',
        width: '25%',
      }}
    >
      <div className="confirmation-content">
        <h3>
          Preço: R$ {value[0]} até R$ {value[1]}
        </h3>
        <Slider style={{ width: '100%' }} value={value} onChange={(e) => setValue(e.value)} range />
      </div>
    </Dialog>
  );
};

const DeleteDialog: React.FC<DeleteDialogProps> = ({ loading, ...props }) => {
  // Template for rendering image column
  const renderFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Resetar" className="p-button-text" onClick={props.onHide} />
      <Button label="Aplicar" disabled={loading} className="p-button-danger" onClick={props.onConfirm} />
    </div>
  );

  return (
    <Dialog modal dismissableMask footer={renderFooter} header="Aviso!" onHide={props.onHide} visible={!!props.data}>
      <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
        <span>
          Deseja remover <b>{props.data && props.data.name}</b>?
        </span>
      </div>
    </Dialog>
  );
};

/**
 * MAIN PAGE
 */

const DEFAULT_PAGINATION_OPTIONS = {
  totalOfRows: 10,
  numberOfPages: 1,
  rowsPerPage: 10,
  currentPage: 0,
  firstRow: 2,
};

const ExamplePage: React.FC<ExamplePageProps> = () => {
  const toast = useRef<Toast>(null);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_OPTIONS);
  const { currentPage, firstRow, rowsPerPage } = pagination;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [deleteRow, setDeleteRow] = useState<FakeServiceGetResponseData | undefined>();
  const [editRow, setEditRow] = useState<FakeServiceGetResponseData | undefined>();

  const { isLoading, data } = useGetDataQuery({
    rowsPerPage,
    offset: currentPage,
  });

  const handleClickFilter = () => {
    setShowFilter(true);
  };

  const handleEditButton = (rowData: FakeServiceGetResponseData) => {
    setEditRow(rowData);
    setShowEdit(true);
  };

  const handleClickNew = () => {
    setEditRow(undefined);
    setShowEdit(true);
  };

  const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleSearchText', event.target.value);
  };

  const handleOnPage = (event: DataTablePageParams) => {
    setPagination({
      ...pagination,
      rowsPerPage: event.rows,
      currentPage: event.page,
      firstRow: event.first,
    });
  };

  const handleConfirmDelete = () => {
    if (deleteRow) {
      // Call the service here to delete the row
      console.log('handleConfirmDelete', deleteRow);
      setDeleteRow(undefined);
    }
  };

  const handleConfirmEdit: EditDialogProps['onConfirm'] = (formData) => {
    if (editRow) {
      // Edit the current row here
      setEditRow(undefined);
    } else {
      // Create a new one here
    }

    // Closes the dialog
    setShowEdit(false);
    console.log('handleConfirmEdit', formData);
  };

  const handleFilter = (filters) => {
    // code your filters here
    console.log('filters', filters);
  };

  // Template for rendering header
  const renderHeaderTemplate = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText onChange={handleSearchText} placeholder="Procurar..." />
        </span>
      </div>
      <div>
        <Button label="Filtrar" icon="pi pi-filter" className="p-button-info p-mr-2" onClick={handleClickFilter} />
        <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={handleClickNew} />
        <FilterDialog data={editRow} onHide={() => setShowFilter(false)} show={showFilter} onFilter={handleFilter} />
      </div>
    </div>
  );

  // Template for rendering image column
  const renderImageTemplate = (rowData: FakeServiceGetResponseData) => (
    <Image src={rowData.image} alt={rowData.image} />
  );

  // Template for rendering Buttons column
  const renderTableButtonsTemplate = (rowData: FakeServiceGetResponseData) => (
    <>
      <Button
        style={{ marginRight: '15px' }}
        icon="pi pi-pencil"
        className="p-button-rounded p-button-outlined"
        onClick={() => handleEditButton(rowData)}
        tooltip="Editar"
        tooltipOptions={{ position: 'top' }}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-outlined p-button-danger"
        onClick={() => setDeleteRow(rowData)}
        tooltip="Excluir"
        tooltipOptions={{ position: 'top' }}
      />
    </>
  );

  return (
    <>
      <Container>
        <Card>
          <DataTable
            header={renderHeaderTemplate}
            loading={isLoading}
            onPage={handleOnPage}
            first={firstRow}
            totalRecords={data?.totalOfRows}
            rowsPerPageOptions={[5, 10]}
            rows={rowsPerPage}
            value={data ? data.data : []}
            emptyMessage="Dados não encontrados"
            resizableColumns
            paginator
            lazy
          >
            <Column field="image" header="Imagem" body={renderImageTemplate} />
            <Column field="name" header="Descrição" />
            <Column field="price" header="Preço" />
            <Column field="stock" header="Estoque" />
            <Column bodyStyle={{ textAlign: 'end' }} body={renderTableButtonsTemplate} />
          </DataTable>
        </Card>
      </Container>
      {/* Models Components */}
      <Toast ref={toast} />
      <DeleteDialog data={deleteRow} onConfirm={handleConfirmDelete} onHide={() => setDeleteRow(undefined)} />
      <EditDialog data={editRow} onConfirm={handleConfirmEdit} onHide={() => setShowEdit(false)} show={showEdit} />
    </>
  );
};

export default ExamplePage;
