import { authHttp } from '../http';

export const USER = '/api/users';
export const USER_DETAILS = '/v1/users/details';

export const getUserByUsername = (payload) => {
  return authHttp
    .get(USER_DETAILS, { params: { username: payload.username } })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default () => ({
  getProtocolo() {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postProtocolo(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  putProtocolo(dados, id) {
    return authHttp
      .put(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos/${id}`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  deleteProtocolo(id) {
    return authHttp
      .delete(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos/${id}`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getTiposProtocolo() {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo/consulta?mostrarInativos=false&size=999999`, {
        params: {},
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  pesquisarImoveis(pesquisa) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/imoveis/consulta-imovel?size=999999&search=${pesquisa}`, {
        params: {},
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getDadosImovel(id) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/imoveis/${id}`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postNomeArquivos(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/salvar-lista`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postArquivos(idArquivo, arquivo) {
    const formData = new FormData();
    formData.append('file', arquivo);

    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/${idArquivo}/upload-arquivo`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
