/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import jwt_decode from 'jwt-decode';

import { addLocale } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Calendar } from 'primereact/calendar';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

const ResiduosContribuinte = (props) => {
  const toast = useRef<any>();
  const basicoService = BasicoService();

  const [carregandoDados, setCarregandoDados] = useState(true);
  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [periodoBusca, setPeriodoBusca] = useState<Date | Date[]>();

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_COL_CON_RES-IMO') {
        temPermissao = true;
        setCarregandoDados(false);
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  addLocale('pt-br', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'Hoje',
    clear: 'Limpar',
  });

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const formatarDataParametro = (d) => {
    if (d) {
      const data = new Date(d);
      if (data) {
        let dia = data.getDate().toString();
        if (dia.length === 1) dia = `0${dia}`;
        let mes = (data.getMonth() + 1).toString();
        if (mes.length === 1) mes = `0${mes}`;
        const ano = data.getFullYear();

        return `${ano}-${mes}-${dia}` ?? '';
      }
    }

    return null;
  };

  const acaoBuscarPontosColeta = async () => {
    if (!periodoBusca) {
      mostrarToast('error', 'Erro!', 'O período é obrigatório para a pesquisa');
    } else {
      let dataInicial: Date = new Date();
      let dataFinal: Date = new Date();

      let dataInicialParametro;
      let dataFinalParametro;

      if (periodoBusca instanceof Array) {
        [dataInicial, dataFinal] = periodoBusca;
      }

      if (dataInicial) dataInicialParametro = formatarDataParametro(dataInicial);
      if (dataFinal) dataFinalParametro = formatarDataParametro(dataFinal);
      else dataFinalParametro = dataInicialParametro;

      setCarregandoDados(true);
      await basicoService
        .get(`/v1/residuos/consulta-imovel?dataInicial=${dataInicialParametro}&dataFinal=${dataFinalParametro}`)
        .then((dadosGet) => {
          setDadosTabela(dadosGet);
        });
    }
    setCarregandoDados(false);
  };

  const formatarPeso = (d: number) => {
    if (d) {
      return `${d.toFixed(3).replace('.', ',')} Kg`;
    }

    return null;
  };

  const formatarDinheiro = (valor) => {
    if (valor) return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    return null;
  };

  const templatePeso = (rowData, campo) => {
    return formatarPeso(rowData[`${campo}`]);
  };

  const templateValor = (rowData, campo) => {
    return formatarDinheiro(rowData[`${campo}`]);
  };

  const cabecalhoTabela = (
    <div>
      <div style={{ marginBottom: '10px' }}>
        <div className="table-header">
          <h5 className="p-m-0">Consulta de Residuos por Contribuinte</h5>
        </div>
      </div>
      <div className="p-fluid p-grid p-formgrid">
        <div className="p-field p-sm-12 p-md-2 p-lg-2">
          <label>Período</label>
          <Calendar
            value={periodoBusca}
            onChange={(e) => setPeriodoBusca(e.value)}
            dateFormat="dd/mm/yy"
            selectionMode="range"
            locale="pt-br"
            readOnlyInput
            showButtonBar
          />
        </div>
        <div className="p-field p-sm-12 p-md-2 p-lg-2" style={{ marginTop: '32px' }}>
          <Button
            label="Buscar"
            icon="pi pi-search"
            className="p-button-info p-mr-2"
            onClick={acaoBuscarPontosColeta}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable emptyMessage="Sem dados" value={dadosTabela} resizableColumns header={cabecalhoTabela}>
              <Column field="contribuinte" header="Contibuinte" />
              <Column
                field="entregas"
                header="Entregas"
                bodyStyle={{ textAlign: 'right' }}
                headerStyle={{ textAlign: 'right' }}
              />
              <Column field="peso" header="Peso" body={(rowData) => templatePeso(rowData, 'peso')} />
              <Column field="valor" header="Valor" body={(rowData) => templateValor(rowData, 'valor')} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Toast ref={toast} />
    </div>
  );
};

export default ResiduosContribuinte;
