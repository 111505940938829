import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { InputTextarea } from 'primereact/inputtextarea';
import { AutoComplete } from 'primereact/autocomplete';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import S3Service, { DefaultS3Service } from '../../../utils/services/s3Service';
import ManutencaoProtocoloService from '../../../utils/services/manutencaoProtocoloService';
import A11yButton from '../../../components/a11yButton';

export interface ListaItensTipo {
  idArquivoItem: string;
  nomeArquivo: string;
}

export interface OcorrenciaCadTipo {
  idProtocolo: string;
  idResponsavel: string;
  ocorrencia: string;
  resposta: string;
  concluido: boolean;
  idArquivo?: string;
  listaItens?: Array<ListaItensTipo>;
}

const ManutencaoProtocolos: React.FC<RouteComponentProps> = (props) => {
  let permissoes: any;

  let fileUploadRef: any;
  const emptyImagensUpload = [
    {
      name: '',
    },
  ];

  const listaSalvar = {
    modulo: 'OCORRENCIA',
    microServico: 'CINCO_R',
    nomeArquivos: [''],
  };

  const emptyManutencaoProtocolo = {
    id: '',
    numero: '',
    dataFato: '',
    tipoProtocolo: {
      descricao: '',
    },
    usuario: {
      nome: '',
    },
    imovel: {
      contribuinte: '',
    },
    telefone: '',
    celular: '',
    email: '',
    status: '',
    descricao: '',
  };

  const emptyOcorrencia = {
    idProtocolo: '',
    ocorrencia: '',
    idArquivo: '',
    listaItens: [],
    resposta: '',
    usuario: {
      nome: '',
    },
    concluido: false,
  };

  const emptyCadOcorrencia: OcorrenciaCadTipo = {
    idProtocolo: '',
    idResponsavel: '',
    ocorrencia: '',
    resposta: '',
    concluido: false,
    idArquivo: undefined,
    listaItens: [],
  };

  const emptyCancelamento = {
    motivo: '',
  };

  const situacoes = [
    { name: 'Aberto', value: 'ABERTO' },
    { name: 'Aguardando', value: 'AGUARDANDO' },
    { name: 'Andamento', value: 'ANDAMENTO' },
    { name: 'Concluído', value: 'CONCLUIDO' },
  ];

  const simNao = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];

  const [salvando, setSalvando] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [filtro, setFiltro] = useState<any>({ numero: '' });
  const [permiGestor, setPermiGestor] = useState<any>(false);

  const [imagensUpload, setImagensUpload] = useState(emptyImagensUpload);

  const [simNaoSelect, setSimNaoSelect] = useState<any>(false);
  const [situacaoSelect, setSituacaoSelect] = useState<any>('ABERTO');

  const toast = useRef<any>();
  const manutencaoProtocoloService = ManutencaoProtocoloService();

  const [submitted, setSubmitted] = useState(false);
  const [filtroAberto, setFiltroAberto] = useState(false);

  const [usuario] = useState<any>([]);
  const [selectedUsuario, setSelectedUsuario] = useState<any>(null);
  const [filteredUsuarios, setFilteredUsuarios] = useState<any>(null);

  const [usuarioResp, setUsuarioResp] = useState<any>([]);
  const [selectedUsuarioResp, setSelectedUsuarioResp] = useState<any>(null);

  const [manutencaoProtocolos, setManutencaoProtocolos] = useState<any[]>([]);
  const [manutencaoProtocolo, setManutencaoProtocolo] = useState(emptyManutencaoProtocolo);
  const [manutencaoProtocoloDialog, setManutencaoProtocoloDialog] = useState(false);

  const [cadastroOcorrenciaDialog, setCadastroOcorrenciaDialog] = useState(false);
  const [imagens, setImagens] = useState<any[]>([]);

  const [ocorrencias, setOcorrencias] = useState<any[]>([]);
  const [ocorrencia, setOcorrencia] = useState(emptyOcorrencia);
  const [cadOcorrencia, setCadOcorrencia] = useState(emptyCadOcorrencia);
  const [ocorrenciaDialog, setOcorrenciaDialog] = useState(false);

  const [submittedCancel, setSubmittedCancel] = useState(false);
  const [cancelamento, setCancelamento] = useState(emptyCancelamento);
  const [cancelarManutencaoProtocoloDialog, setCancelManutencaoProtocoloDialog] = useState(false);

  const consultarProtocolos = () => {
    manutencaoProtocoloService.getManutencaoProtocolos(filtro.numero, situacaoSelect, usuario.id).then((data) => {
      setManutencaoProtocolos(data);
      setCarregandoDados(false);
    });
  };

  useEffect(() => {
    let temPermissao = false;
    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    setPermiGestor(false);
    const getUser: any = localStorage.getItem('user');
    const user = JSON.parse(getUser);
    setSelectedUsuario(user);

    for (let i = 0; i < permissoes.length; i += 1) {
      if (permissoes[i] === '5R_OUV_PROT_MANUTENC_PROT') {
        temPermissao = true;
        setPermiGestor(true);
        setSelectedUsuario('');
        consultarProtocolos();
      }
      if (i + 1 === permissoes.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const onSitucaoChange = (e: { value: any }) => {
    setSituacaoSelect(e.value);
  };

  const onSimNaoChange = (e: { value: any }) => {
    setSimNaoSelect(e.value);
    if (e.value === true) {
      usuarioResp.id = null;
      setSelectedUsuario(null);
    }
  };

  const AbrirFiltro = () => {
    setFiltroAberto(!filtroAberto);
  };

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const openNew = () => {
    setManutencaoProtocolo(emptyManutencaoProtocolo);
    setSubmitted(false);
    setManutencaoProtocoloDialog(true);
  };

  const hideDialog = () => {
    setSalvando(false);
    setUsuarioResp([]);
    setSelectedUsuarioResp(null);
    setSubmitted(false);
    setSimNaoSelect(false);
    setSubmittedCancel(false);
    setCadastroOcorrenciaDialog(false);
    setManutencaoProtocoloDialog(false);
    setCancelManutencaoProtocoloDialog(false);
    setCancelamento(emptyCancelamento);
    setCadOcorrencia(emptyCadOcorrencia);
    setManutencaoProtocolo(emptyManutencaoProtocolo);
  };

  const hideDialogOcorrencia = () => {
    setOcorrenciaDialog(false);
    setOcorrencia(emptyOcorrencia);
    setImagens([]);
  };

  const detalhamentoManutencaoProtocolo = (_manutencaoProtocolo) => {
    manutencaoProtocoloService.getOcorrencias(_manutencaoProtocolo.id).then((data) => {
      setOcorrencias(data.listaOcorrencias);
    });
    setManutencaoProtocolo({ ..._manutencaoProtocolo });
    setManutencaoProtocoloDialog(true);
  };

  const detalhamentoOcorrencia = (detalhesOcorrencia) => {
    setOcorrencia(detalhesOcorrencia);
    setOcorrenciaDialog(true);
    if (detalhesOcorrencia.idArquivo) {
      manutencaoProtocoloService.getIdArquivo(detalhesOcorrencia.idArquivo).then((data) => {
        if (data && data[0] && data[0].id) {
          setImagens(data);
        } else {
          mostrarToast('error', 'Erro!', `Ocorreu um erro ao consultar a imagem do anexo.`);
        }
      });
    }
  };

  const cadastroOcorrencia = (_manutencaoProtocolo) => {
    setManutencaoProtocolo(_manutencaoProtocolo);
    setCadastroOcorrenciaDialog(true);
  };

  const confirmCancelManutencaoProtocolo = (_manutencaoProtocolo) => {
    setManutencaoProtocolo(_manutencaoProtocolo);
    setCancelManutencaoProtocoloDialog(true);
  };

  const hideCancelManutencaoProtocoloDialog = () => {
    setSubmittedCancel(false);
    setCancelManutencaoProtocoloDialog(false);
    setCancelamento(emptyCancelamento);
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    if (dados === 'numero') {
      const _filtro = { ...filtro };
      _filtro[`${dados}`] = val;
      setFiltro(_filtro);
    } else if (dados === 'motivo') {
      const _cancelamento = { ...cancelamento };
      _cancelamento[`${dados}`] = val;
      setCancelamento(_cancelamento);
    } else {
      const _manutencaoProtocolo = { ...manutencaoProtocolo };
      _manutencaoProtocolo[`${dados}`] = val;
      setManutencaoProtocolo(_manutencaoProtocolo);
    }
  };

  const onCadOcorrenciaChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _cadOcorrencia = { ...cadOcorrencia };
    _cadOcorrencia[`${dados}`] = val;
    setCadOcorrencia(_cadOcorrencia);
  };

  const cancelManutencaoProtocolo = () => {
    setSalvando(true);
    setSubmittedCancel(true);
    if (cancelamento.motivo.trim()) {
      manutencaoProtocoloService.cancelamentoManutencaoProtocolos(manutencaoProtocolo.id, cancelamento).then(() => {
        mostrarToast('info', 'Aviso!', 'Protocolo cancelado.');
        setManutencaoProtocolo(emptyManutencaoProtocolo);
        consultarProtocolos();
        hideDialog();
      });
    } else {
      setSalvando(false);
    }
  };

  const searchUsuarios = (event: { query: string }) => {
    setTimeout(() => {
      let _filteredUsuarios: any = [];
      manutencaoProtocoloService.getUsuarios(event.query.trim()).then(
        (data) => {
          for (let i = 0; i < data.length; i += 1) {
            _filteredUsuarios.push(data[i]);
          }
          setFilteredUsuarios(_filteredUsuarios);
        },
        (error) => {
          _filteredUsuarios = [];
          setFilteredUsuarios(_filteredUsuarios);
        },
      );
    }, 250);
  };

  const usuarioSelecionado = (dadosEvento) => {
    usuario.id = dadosEvento.id;
  };

  const usuarioLimpo = (dados) => {
    if (dados.value === '' || dados.value === null) {
      usuario.id = '';
      setSelectedUsuario(dados.value);
    } else {
      setSelectedUsuario(dados.value);
    }
  };

  const usuarioRespSelecionado = (dadosEvento) => {
    usuarioResp.id = dadosEvento.id;
  };

  const usuarioRespLimpo = (dados) => {
    setSelectedUsuarioResp(dados.value);
  };

  const saveCadOcorrencia = () => {
    setSalvando(true);
    setSubmittedCancel(true);

    const refUploader = fileUploadRef;
    const refEmptyImagens = emptyImagensUpload;

    cadOcorrencia.idProtocolo = manutencaoProtocolo.id;
    cadOcorrencia.idResponsavel = usuarioResp.id;
    cadOcorrencia.concluido = simNaoSelect;

    listaSalvar.nomeArquivos = [];
    for (let i = 0; i < imagensUpload.length; i += 1) {
      if (imagensUpload[i].name) {
        listaSalvar.nomeArquivos.push(imagensUpload[i].name);
      }
    }

    if (cadOcorrencia.ocorrencia.trim() && !(cadOcorrencia.concluido && cadOcorrencia.resposta === '')) {
      if (imagensUpload.length > 1) {
        manutencaoProtocoloService.postNomeArquivos(listaSalvar).then((dados) => {
          cadOcorrencia.idArquivo = dados.idArquivo;
          cadOcorrencia.listaItens = dados.listaItens;
          for (let i = 0; i < imagensUpload.length; i += 1) {
            if (imagensUpload[i].name !== '') {
              if (cadOcorrencia.listaItens) {
                // eslint-disable-next-line dot-notation
                DefaultS3Service.uploadFile(
                  `${cadOcorrencia.idArquivo}_${cadOcorrencia.listaItens[i - 1].idArquivoItem}_${
                    cadOcorrencia.listaItens[i - 1].nomeArquivo.split('.')[0]
                  }`,
                  imagensUpload[i],
                ).then(() => {
                  if (i + 1 === imagensUpload.length) {
                    manutencaoProtocoloService.postOcorrencia(cadOcorrencia).then(() => {
                      mostrarToast('success', 'Sucesso!', 'Ocorrência criada.');
                      refUploader.clear();
                      setImagensUpload(refEmptyImagens);
                      setCadOcorrencia({ ...emptyCadOcorrencia });
                      consultarProtocolos();
                      hideDialog();
                    });
                  }
                  setManutencaoProtocoloDialog(false);
                });
              }
            }
          }
        });
      } else {
        manutencaoProtocoloService.postOcorrencia(cadOcorrencia).then(() => {
          mostrarToast('success', 'Sucesso!', 'Ocorrência criada.');
          setCadOcorrencia({ ...emptyCadOcorrencia });
          consultarProtocolos();
          hideDialog();
        });
        setManutencaoProtocoloDialog(false);
      }
    } else {
      setSalvando(false);
    }
  };

  const addImgUpload = (event) => {
    if (event.files[0].size <= 1048576) {
      imagensUpload.push(event.files[0]);
    }
  };

  const removeImgUpload = (event) => {
    const arquivo = event.file;
    for (let i = 0; i < imagensUpload.length; i += 1) {
      if (imagensUpload[i] === arquivo) {
        imagensUpload.splice(i, 1);
      }
    }
  };

  const buildUrlImg = (dados) => {
    let tipoArquivo: string = dados.nomeArquivo.replace(/"/g, '').slice(dados.nomeArquivo.lastIndexOf('.'));

    if (tipoArquivo === '.jpg') {
      tipoArquivo = '.jpeg';
    }

    return `http://${process.env.REACT_APP_BUCKET_NAME}.s3.sa-east-1.amazonaws.com/${
      process.env.REACT_APP_DIR_NAME
    }/OCORRENCIA/${dados.local.split('OCORRENCIA/')[1].split('.')[0] + tipoArquivo}`;
  };

  const abrirImagem = (dados) => {
    const win = window.open(buildUrlImg(dados));
    if (win != null) {
      win.focus();
    }
  };

  const baixarImagem = (dadosImg) => {
    manutencaoProtocoloService.baixarImagemBackend(dadosImg);
  };

  const botoesTabelaTemplate = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-outlined"
          tooltip="Ocorrências"
          tooltipOptions={{ position: 'top' }}
          onClick={() => detalhamentoManutencaoProtocolo(rowData)}
        />
        {(rowData.status === 'AGUARDANDO' || rowData.status === 'ANDAMENTO') && (
          <Button
            style={{ marginLeft: '15px' }}
            icon="pi pi-plus"
            className="p-button-rounded p-button-outlined p-button-success"
            tooltip="Nova Ocorrência"
            tooltipOptions={{ position: 'top' }}
            onClick={() => cadastroOcorrencia(rowData)}
          />
        )}
        {rowData.status === 'AGUARDANDO' && permiGestor === true && (
          <Button
            style={{ marginLeft: '15px' }}
            icon="pi pi-times"
            className="p-button-rounded p-button-outlined p-button-danger"
            onClick={() => confirmCancelManutencaoProtocolo(rowData)}
            tooltip="Cancelar"
            tooltipOptions={{ position: 'top' }}
          />
        )}
      </>
    );
  };

  const botoesTabelaOcorrencia = (rowData) => {
    return (
      <>
        {rowData.idArquivo && (
          <Button
            icon="pi pi-paperclip"
            className="p-button-rounded p-button-outlined"
            tooltip="Anexo"
            tooltipOptions={{ position: 'top' }}
            onClick={() => detalhamentoOcorrencia(rowData)}
          />
        )}
      </>
    );
  };

  const cancelManutencaoProtocoloDialogFooter = (
    <>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideCancelManutencaoProtocoloDialog} />
      <Button
        label="Sim"
        disabled={salvando}
        icon="pi pi-check"
        className="p-button-danger"
        onClick={cancelManutencaoProtocolo}
      />
    </>
  );

  const manutencaoProtocoloDialogFooter = (
    <>
      <Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
    </>
  );

  const detalhesOcorrenciaDialogFooter = (
    <>
      <Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={hideDialogOcorrencia} />
    </>
  );

  const cadastroOcorrenciaDialogFooter = (
    <>
      <Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        label="Salvar"
        disabled={salvando}
        icon="pi pi-check"
        className="p-button-success"
        onClick={saveCadOcorrencia}
      />
    </>
  );

  const headerManutencao = (
    <div className="p-fluid p-grid">
      <div className="p-sm-12" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h5 className="p-m-0">Consulta de Manutenção do Protocolo</h5>

        <div>
          <Button type="button" className="p-button-text p-button-rounded" onClick={AbrirFiltro}>
            <p style={{ margin: '0 5px 0 0' }}>Filtros</p>
            {!filtroAberto && <i className="pi pi-angle-down" style={{ fontSize: '20px' }} />}
            {filtroAberto && <i className="pi pi-angle-up" style={{ fontSize: '20px' }} />}
          </Button>
        </div>
      </div>

      {filtroAberto && (
        <div className="p-sm-12 p-grid">
          <div className="p-sm-12 p-md-4 p-lg-4">
            <p style={{ margin: '5px', fontWeight: 300 }}>Número do Protocolo</p>
            <InputText
              maxLength={50}
              style={{ fontWeight: 300 }}
              value={filtro.numero}
              onChange={(e) => onInputChange(e, 'numero')}
            />
          </div>

          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', fontWeight: 300 }}>Responsável</p>
            <AutoComplete
              value={selectedUsuario}
              disabled={!permiGestor}
              forceSelection
              suggestions={filteredUsuarios}
              completeMethod={searchUsuarios}
              field="nome"
              minLength={3}
              onSelect={(e) => usuarioSelecionado(e.value)}
              onChange={(e) => usuarioLimpo(e)}
            />
          </div>

          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', fontWeight: 300 }}>Situação</p>
            <Dropdown
              style={{ fontWeight: 300, marginTop: '10px' }}
              value={situacaoSelect}
              options={situacoes}
              onChange={onSitucaoChange}
              optionLabel="name"
            />
          </div>

          <div className="p-sm-12 p-md-2 p-lg-2" style={{ alignSelf: 'flex-end' }}>
            <Button label="Aplicar" onClick={consultarProtocolos} />
          </div>
        </div>
      )}
    </div>
  );

  const headerOcorrenca = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Ocorrências</h5>
      </div>
    </div>
  );

  const dataTemplate = (rowData) => {
    if (rowData.dataFato) {
      const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(rowData.dataFato));
      return <span> {dataFormatada} </span>;
    }
    if (rowData.createdAt) {
      const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(rowData.createdAt));
      return <span> {dataFormatada} </span>;
    }
    return '';
  };

  const formatEndereco = (dados) => {
    if (dados && dados.imovel && dados.imovel.logradouro && dados.imovel.bairro && dados.imovel.numero) {
      const endereco = `${
        dados.imovel.logradouro.charAt(0).toUpperCase() + dados.imovel.logradouro.slice(1).toLowerCase()
      } - ${
        dados.imovel.bairro.charAt(0).toUpperCase() + dados.imovel.bairro.slice(1).toLowerCase()
      } - ${dados.imovel.numero.toLowerCase()}`;
      return endereco;
    }
    return '';
  };

  const enderecoTemplate = (rowData) => {
    const endereco = formatEndereco(rowData);
    return <span>{endereco}</span>;
  };

  const situacaoTemplate = (rowData) => {
    return <span className={`table-badge status-${rowData.status.toLowerCase()}`}>{rowData.status}</span>;
  };

  const formatarData = (dados) => {
    if (dados) {
      const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(dados));
      return dataFormatada;
    }
    return '';
  };

  const chooseOptions = { label: 'Procurar', icon: 'pi pi-fw pi-plus' };
  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent' }}>
        {chooseButton}
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i
          className="pi pi-file p-p-3"
          style={{
            fontSize: '2em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        />
        <span style={{ fontSize: '1em', color: 'var(--text-color-secondary)' }} className="p-my-3">
          Arraste e solte aqui!
        </span>
      </div>
    );
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={manutencaoProtocolos}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={headerManutencao}
            >
              <Column field="numero" header="Protocolo" style={{ width: '155px' }} />
              <Column header="Data" body={dataTemplate} />
              <Column field="tipoProtocolo.descricao" header="Tipo de Protocolo" />
              <Column header="Endereço" body={enderecoTemplate} />
              <Column header="Status" body={situacaoTemplate} />
              <Column field="usuario.nome" header="Responsável" />
              <Column bodyStyle={{ textAlign: 'end' }} body={botoesTabelaTemplate} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={manutencaoProtocoloDialog}
        style={{ width: '1200px' }}
        header="Detalhes do Protocolo"
        modal
        className="p-fluid"
        footer={manutencaoProtocoloDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Número do Protocolo</p>
            <InputText disabled value={manutencaoProtocolo.numero} />
          </div>

          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Data</p>
            <InputText disabled value={formatarData(manutencaoProtocolo.dataFato)} />
          </div>

          <div className="p-sm-12 p-md-4 p-lg-4">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Tipo do Protocolo</p>
            <InputText disabled value={manutencaoProtocolo.tipoProtocolo.descricao} />
          </div>

          <div className="p-sm-12 p-md-2 p-lg-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: 'fit-content' }}>
              <p style={{ margin: '10px 5px 13px 5px', color: '#a6a6a6', textAlign: 'start' }}>Status</p>
              <span className={`table-badge status-${manutencaoProtocolo.status.toLowerCase()}`}>
                {manutencaoProtocolo.status.toLowerCase()}
              </span>
            </div>
          </div>

          <div className="p-sm-12 p-md-9 p-lg-9">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Contribuinte</p>
            <InputText disabled value={manutencaoProtocolo.imovel.contribuinte} />
          </div>

          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Celular</p>
            <InputText disabled value={manutencaoProtocolo.celular} />
          </div>

          <div className="p-sm-12 p-md-9 p-lg-9">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Email</p>
            <InputText disabled value={manutencaoProtocolo.email} />
          </div>

          <div className="p-sm-12 p-md-3 p-lg-3">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Telefone</p>
            <InputText disabled value={manutencaoProtocolo.telefone} />
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Endereço</p>
            <InputText disabled value={formatEndereco(manutencaoProtocolo)} />
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Descrição</p>
            <InputTextarea disabled rows={15} value={manutencaoProtocolo.descricao} />
          </div>
        </div>

        {ocorrencias[0] && (
          <div style={{ width: '-webkit-fill-available' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={ocorrencias}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={headerOcorrenca}
            >
              <Column header="Data" body={dataTemplate} />
              <Column field="usuario.nome" header="Usuário" />
              <Column field="ocorrencia" header="Ocorrência" />
              <Column bodyStyle={{ textAlign: 'end' }} body={botoesTabelaOcorrencia} />
            </DataTable>
          </div>
        )}
      </Dialog>

      <Dialog
        visible={cadastroOcorrenciaDialog}
        style={{ width: '1200px' }}
        header="Nova Ocorrência"
        modal
        className="p-fluid"
        footer={cadastroOcorrenciaDialogFooter}
        onHide={hideDialog}
      >
        <div style={{ display: 'flex' }}>
          <div className="p-grid" style={{ width: '-webkit-fill-available', paddingRight: '10px' }}>
            <div className="p-sm-12 p-md-4 p-lg-4" style={{ maxHeight: '80px' }}>
              <p style={{ margin: '5px', color: '#a6a6a6' }}>Número do Protocolo</p>
              <InputText disabled value={manutencaoProtocolo.numero} />
            </div>

            <div className="p-sm-8 p-md-4 p-lg-4" style={{ maxHeight: '80px' }}>
              <p style={{ margin: '5px', color: '#a6a6a6' }}>Data</p>
              <InputText disabled value={formatarData(manutencaoProtocolo.dataFato)} />
            </div>

            <div
              className="p-sm-4 p-md-4 p-lg-4"
              style={{ display: 'flex', justifyContent: 'flex-end', maxHeight: '80px' }}
            >
              <div style={{ width: 'fit-content' }}>
                <p style={{ margin: '10px 5px 13px 5px', color: '#a6a6a6', textAlign: 'start' }}>Status</p>
                <span className={`table-badge status-${manutencaoProtocolo.status.toLowerCase()}`}>
                  {manutencaoProtocolo.status.toLowerCase()}
                </span>
              </div>
            </div>

            <div className="p-sm-12 p-md-12 p-lg-12" style={{ maxHeight: '80px' }}>
              <p style={{ margin: '5px', color: '#a6a6a6' }}>Tipo do Protocolo</p>
              <InputText disabled value={manutencaoProtocolo.tipoProtocolo.descricao} />
            </div>

            <div className="p-sm-12 p-md-12 p-lg-12" style={{}}>
              <p style={{ margin: '5px', color: '#a6a6a6' }}>Contribuinte</p>
              <InputText disabled value={manutencaoProtocolo.imovel.contribuinte} />
            </div>

            <div className="p-sm-12 p-md-12 p-lg-12">
              <p style={{ margin: '5px', color: '#a6a6a6' }}>Endereço</p>
              <InputText disabled value={formatEndereco(manutencaoProtocolo)} />
            </div>

            {!simNaoSelect && (
              <div className="p-sm-12 p-md-12 p-lg-12">
                <p style={{ margin: '5px', color: '#a6a6a6' }}>Descrição</p>
                <InputTextarea disabled rows={10} value={manutencaoProtocolo.descricao} />
              </div>
            )}

            {simNaoSelect && (
              <div className="p-sm-12 p-md-12 p-lg-12">
                <p style={{ margin: '5px', color: '#a6a6a6' }}>Descrição</p>
                <InputTextarea disabled rows={23} value={manutencaoProtocolo.descricao} />
              </div>
            )}
          </div>
          <div style={{ backgroundColor: '#e5e5e5', width: '1px' }} />
          <div className="p-grid" style={{ width: '-webkit-fill-available', paddingLeft: '10px' }}>
            <div className="p-sm-12 p-md-12 p-lg-12">
              <p style={{ margin: '5px' }}>Ocorrência</p>
              <InputTextarea
                rows={6}
                maxLength={2048}
                value={cadOcorrencia.ocorrencia}
                autoFocus
                onChange={(e) => onCadOcorrenciaChange(e, 'ocorrencia')}
                className={classNames({ 'p-invalid': submitted && !cadOcorrencia.ocorrencia })}
              />
              {submittedCancel && !cadOcorrencia.ocorrencia.trim() && (
                <small className="p-error">Campo obrigatório.</small>
              )}
            </div>

            <div className="p-sm-12 p-md-9 p-lg-9">
              <p style={{ margin: '5px' }}>Responsável</p>
              {!simNaoSelect && (
                <AutoComplete
                  value={selectedUsuarioResp}
                  forceSelection
                  suggestions={filteredUsuarios}
                  completeMethod={searchUsuarios}
                  field="nome"
                  minLength={3}
                  onSelect={(e) => usuarioRespSelecionado(e.value)}
                  onChange={(e) => usuarioRespLimpo(e)}
                />
              )}
              {simNaoSelect && <InputText disabled />}
            </div>

            <div className="p-sm-12 p-md-3 p-lg-3">
              <p style={{ margin: '5px' }}>Concluído</p>
              <Dropdown
                disabled={!permiGestor}
                style={{ marginTop: '10px' }}
                value={simNaoSelect}
                options={simNao}
                onChange={onSimNaoChange}
                optionLabel="name"
              />
            </div>

            {simNaoSelect && (
              <div className="p-sm-12 p-md-12 p-lg-12">
                <p style={{ margin: '5px' }}>Resposta</p>
                <InputTextarea
                  rows={10}
                  maxLength={2016}
                  value={cadOcorrencia.resposta}
                  onChange={(e) => onCadOcorrenciaChange(e, 'resposta')}
                />
                {submittedCancel && !cadOcorrencia.resposta.trim() && (
                  <small className="p-error">Campo obrigatório.</small>
                )}
              </div>
            )}

            <div className="p-sm-12 p-md-12 p-lg-12">
              <p style={{ margin: '5px' }}>Anexo</p>
              <FileUpload
                name="demo[]"
                url="./upload"
                customUpload
                maxFileSize={1000000}
                onSelect={addImgUpload}
                onRemove={removeImgUpload}
                headerTemplate={headerTemplate}
                emptyTemplate={emptyTemplate}
                accept="image/*"
                chooseOptions={chooseOptions}
                ref={(el) => {
                  fileUploadRef = el;
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={cancelarManutencaoProtocoloDialog}
        className="p-fluid"
        style={{ width: '650px' }}
        header="Cancelamento de Protocolo"
        modal
        footer={cancelManutencaoProtocoloDialogFooter}
        onHide={hideCancelManutencaoProtocoloDialog}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-5 p-lg-5">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Número do Protocolo</p>
            <InputText disabled value={manutencaoProtocolo.numero} />
          </div>

          <div className="p-sm-12 p-md-5 p-lg-5">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Tipo do Protocolo</p>
            <InputText disabled value={manutencaoProtocolo.tipoProtocolo.descricao} />
          </div>

          <div className="p-sm-12 p-md-2 p-lg-2" style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: 'fit-content' }}>
              <p style={{ margin: '10px 5px 13px 5px', color: '#a6a6a6', textAlign: 'start' }}>Status</p>
              <span
                style={{ fontSize: '14px' }}
                className={`table-badge status-${manutencaoProtocolo.status.toLowerCase()}`}
              >
                {manutencaoProtocolo.status.toLowerCase()}
              </span>
            </div>
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Contribuinte</p>
            <InputText disabled value={manutencaoProtocolo.imovel.contribuinte} />
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Endereço</p>
            <InputText disabled value={formatEndereco(manutencaoProtocolo)} />
          </div>

          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px' }}>Motivo do Cancelamento</p>
            <InputTextarea
              rows={5}
              value={cancelamento.motivo}
              autoFocus
              onChange={(e) => onInputChange(e, 'motivo')}
            />
            {submittedCancel && !cancelamento.motivo.trim() && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={ocorrenciaDialog}
        style={{ width: '1200px' }}
        header="Anexos da Ocorrência"
        modal
        className="p-fluid"
        footer={detalhesOcorrenciaDialogFooter}
        onHide={hideDialogOcorrencia}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-6 p-lg-6">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Número do Protocolo</p>
            <InputText disabled value={manutencaoProtocolo.numero} />
          </div>

          <div className="p-sm-12 p-md-6 p-lg-6">
            <p style={{ margin: '5px', color: '#a6a6a6' }}>Data</p>
            <InputText disabled value={formatarData(manutencaoProtocolo.dataFato)} />
          </div>
        </div>

        {imagens[0] && (
          <div className="p-sm-12 p-md-12 p-lg-12">
            <p style={{ margin: '5px' }}>Imagens</p>
            <div style={{ display: 'flex' }}>
              {imagens.map((imagem) => (
                <div style={{ display: 'grid' }}>
                  <A11yButton
                    onClick={() => abrirImagem(imagem)}
                    elementType="img"
                    elementProps={{
                      style: { width: '100px', height: '100px', margin: '10px' },
                      src: buildUrlImg(imagem),
                      alt: 'Attachment',
                    }}
                  />
                  <div style={{ justifySelf: 'center' }}>
                    <Button icon="pi pi-download" className="p-button-outlined" onClick={() => baixarImagem(imagem)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default ManutencaoProtocolos;
