import Keycloak from 'keycloak-js';

// const keycloakConfig = JSON.parse(process.env.REACT_APP_KEYCLOAK_JSON);
const realm = window.location.hostname.split('.')[0];
const resource = window.location.hostname.split('.')[0];

export const keycloak = Keycloak({
  url: `${process.env.REACT_APP_KSI_URL_AUTH}/auth/`,
  realm,
  clientId: resource,
});

// export const keycloakProviderInitConfig = {
//   onLoad: "check-sso",
// };
