import React, { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classNames from 'classnames';

import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';

import TipoProtocoloService from '../../../utils/services/tipoProtocoloService';

const TiposProtocolo: React.FC<RouteComponentProps> = (props) => {
  let permissoes: any;

  const emptyTipoProtocolo = {
    id: '',
    descricao: '',
    ativo: 'true',
  };

  const opcoesAtivo = [
    { label: 'Ativo', value: 'true' },
    { label: 'Inativo', value: 'false' },
  ];

  const toast = useRef<Toast>(null);

  const [salvando, setSalvando] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [tiposProtocolo, setTiposProtocolo] = useState<any[]>([]);
  const tipoProtocoloService = TipoProtocoloService();

  const [tipoProtocolo, setTipoProtocolo] = useState(emptyTipoProtocolo);
  const [tipoProtocoloDialog, setTipoProtocoloDialog] = useState(false);

  const [deleteTipoProtocoloDialog, setDeleteTipoProtocoloDialog] = useState(false);

  const consultarProtocolos = () => {
    tipoProtocoloService.getTiposProtocolo().then((response) => {
      const data = response;
      if (data) {
        for (let i = 0; i < data.length; i += 1) {
          data[i].ativo = data[i].ativo.toString();
        }
        setTiposProtocolo(data);
        setCarregandoDados(false);
      }
    });
  };

  const mostrarToast = (tipo, titulo, texto) => {
    if (toast.current) {
      toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
    }
  };

  useEffect(() => {
    let temPermissao = false;
    const getPermissoes: any = localStorage.getItem('permissoes');
    permissoes = JSON.parse(getPermissoes);

    for (let i = 0; i < permissoes.length; i += 1) {
      if (permissoes[i] === '5R_OUV_CAD_TIP_PRO') {
        temPermissao = true;
        consultarProtocolos();
      }
      if (i + 1 === permissoes.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const openNew = () => {
    setTipoProtocolo(emptyTipoProtocolo);
    setSubmitted(false);
    setTipoProtocoloDialog(true);
  };

  const hideDialog = () => {
    setSalvando(false);
    setSubmitted(false);
    setTipoProtocoloDialog(false);
    setDeleteTipoProtocoloDialog(false);
    setTipoProtocolo(emptyTipoProtocolo);
  };

  const editTipoProtocolo = (_tipoProtocolo) => {
    setTipoProtocolo({ ..._tipoProtocolo });
    setTipoProtocoloDialog(true);
  };

  const confirmDeleteTipoProtocolo = (_tipoProtocolo) => {
    setTipoProtocolo(_tipoProtocolo);
    setDeleteTipoProtocoloDialog(true);
  };

  const hideDeleteTipoProtocoloDialog = () => {
    setDeleteTipoProtocoloDialog(false);
  };

  const onInputChange = (e, dados) => {
    const val = (e.target && e.target.value) || '';
    const _tipoProtocolo = { ...tipoProtocolo };
    _tipoProtocolo[`${dados}`] = val;
    setTipoProtocolo(_tipoProtocolo);
  };

  const deleteTipoProtocolo = () => {
    setSalvando(true);
    tipoProtocoloService.deleteTiposProtocolo(tipoProtocolo.id).then((retorno) => {
      if (retorno.status === 'PRECONDITION_FAILED') {
        mostrarToast('error', 'Aviso!', 'Já existe protocolo lançado para esse tipo de protocolo.');
      } else {
        mostrarToast('info', 'Aviso!', 'Tipo de Protocolo deletado.');
      }

      hideDialog();
      consultarProtocolos();
    });
  };

  const saveTipoProtocolo = () => {
    setSubmitted(true);
    if (tipoProtocolo.descricao.trim() && tipoProtocolo.ativo) {
      setSalvando(true);
      if (tipoProtocolo.id === '') {
        tipoProtocoloService.postTiposProtocolo(tipoProtocolo).then(() => {
          mostrarToast('success', 'Sucesso!', 'Tipo de Protocolo criado.');
          hideDialog();
          consultarProtocolos();
        });
      } else {
        tipoProtocoloService.putTiposProtocolo(tipoProtocolo, tipoProtocolo.id).then(() => {
          mostrarToast('success', 'Sucesso!', 'Tipo de Protocolo editado.');
          hideDialog();
          consultarProtocolos();
        });
      }
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
          onClick={() => editTipoProtocolo(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
          onClick={() => confirmDeleteTipoProtocolo(rowData)}
        />
      </>
    );
  };

  const deleteTipoProtocoloDialogFooter = (
    <>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoProtocoloDialog} />
      <Button
        label="Sim"
        disabled={salvando}
        icon="pi pi-check"
        className="p-button-danger"
        onClick={deleteTipoProtocolo}
      />
    </>
  );

  const tipoProtocoloDialogFooter = (
    <>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        label="Salvar"
        disabled={salvando}
        icon="pi pi-check"
        className="p-button-success"
        onClick={saveTipoProtocolo}
      />
    </>
  );

  const header = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Tipos de Protocolo</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={openNew} />
    </div>
  );

  const ativoEstilo = (rowData) => {
    if (rowData.ativo === 'true') {
      return <span className="table-badge status-ativo">Ativo</span>;
    }
    return <span className="table-badge status-inativo">Inativo</span>;
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={tiposProtocolo}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={header}
            >
              <Column field="descricao" header="Descrição" />
              <Column header="Situação" body={ativoEstilo} />
              <Column bodyStyle={{ textAlign: 'end' }} body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={tipoProtocoloDialog}
        style={{ width: '600px' }}
        header="Cadastro de Tipo de Protocolo"
        modal
        className="p-fluid"
        footer={tipoProtocoloDialogFooter}
        onHide={hideDialog}
      >
        <div className="p-field">
          <label htmlFor="descricao">Descrição</label>
          <InputText
            maxLength={50}
            style={{ marginTop: '5px' }}
            id="descricao"
            value={tipoProtocolo.descricao}
            onChange={(e) => onInputChange(e, 'descricao')}
            required
            autoFocus
            className={classNames({ 'p-invalid': submitted && !tipoProtocolo.descricao })}
          />
          {submitted && !tipoProtocolo.descricao && <small className="p-error">Campo obrigatório.</small>}
        </div>

        {tipoProtocolo.id && (
          <div style={{ marginTop: '10px', width: 'fit-content' }} className="p-field">
            <label htmlFor="ativo">Situação</label>
            <SelectButton
              value={tipoProtocolo.ativo}
              options={opcoesAtivo}
              onChange={(e) => onInputChange(e, 'ativo')}
              className={classNames({ 'p-invalid': submitted && !tipoProtocolo.ativo })}
            />
            {submitted && !tipoProtocolo.ativo && <small className="p-error">Campo obrigatório.</small>}
          </div>
        )}
      </Dialog>

      <Dialog
        visible={deleteTipoProtocoloDialog}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={hideDeleteTipoProtocoloDialog}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {tipoProtocolo && (
            <span>
              Deseja remover esse tipo de protocolo <b>{tipoProtocolo.descricao}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default TiposProtocolo;
