/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { keycloak } from './auth';

export const authHttp = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

authHttp.interceptors.request.use((config) => {
  if (keycloak?.token) {
    config.headers.Authorization = `Bearer ${keycloak?.token}`;
    return config;
  }
  return new Promise((resolve, reject) => {
    keycloak.onAuthSuccess = () => {
      config.headers.Authorization = `Bearer ${keycloak?.token}`;
      console.log('resolve: ', config);
      resolve(config);
    };
    keycloak.onAuthError = () => {
      console.log('reject: ', config);
      reject(config);
    };
  });
});

authHttp.interceptors.response.use(undefined, function axiosRetryInterceptor(retorno) {
  return retorno.response;
});
