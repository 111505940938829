import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Provider } from 'react-redux';
import GlobalStyle from './style/GlobalStyle';
import { keycloak } from './utils/auth';
import { Routes } from './Routes';
import { store } from './store';
import 'primeflex/primeflex.css';
import './style/App.scss';

const App: React.FC = () => (
  <div className="App">
    <Provider store={store}>
      <ReactKeycloakProvider authClient={keycloak}>
        <GlobalStyle />
        <Routes />
      </ReactKeycloakProvider>
    </Provider>
  </div>
);

export default App;
