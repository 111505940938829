import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import jwt_decode from 'jwt-decode';

import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { keycloak } from '../../../utils/auth';
import BasicoService from '../../../utils/services/basicoService';

const ColetasSeletivas = (props) => {
  const dadosSalvarVazio = {
    id: '',
    bairro: '',
    diaSemana: '',
    turnoColeta: '',
  };

  const dropdownDiaSemanaOpcoes = [
    { texto: 'Domingo', valor: 0 },
    { texto: 'Segunda', valor: 1 },
    { texto: 'Terça', valor: 2 },
    { texto: 'Quarta', valor: 3 },
    { texto: 'Quinta', valor: 4 },
    { texto: 'Sexta', valor: 5 },
    { texto: 'Sábado', valor: 6 },
  ];

  const dropdownTurnoColetaOpcoes = [
    { texto: 'Manhã', valor: 'MANHA' },
    { texto: 'Tarde', valor: 'TARDE' },
  ];

  const toast = useRef<any>();
  const basicoService = BasicoService();

  const [salvando, setSalvando] = useState(false);
  const [tentarSalvar, setTentarSalvar] = useState(false);
  const [carregandoDados, setCarregandoDados] = useState(true);

  const [dadosTabela, setDadosTabela] = useState<any[]>([]);
  const [dadosSalvar, setDadosSalvar] = useState(dadosSalvarVazio);

  const [dropdownTurnoColetaValue, setDropdownTurnoColetaValue] = useState<any>(null);
  const [dropdownDiaSemanaValue, setDropdownDiaSemanaValue] = useState<any>(null);

  const [mostrarDialogDeletar, setMostrarDialogDeletar] = useState(false);
  const [mostrarDialogCriarEditar, setMostrarDialogCriarEditar] = useState(false);

  const mostrarToast = (tipo, titulo, texto) => {
    toast.current.show({ severity: tipo, summary: titulo, detail: texto, life: 5000 });
  };

  const consultarDadosTabela = () => {
    basicoService.get('/v1/coletas-seletivas?size=99999').then((dadosGet) => {
      setDadosTabela(dadosGet);
      setCarregandoDados(false);
    });
  };

  const acaoFecharDialogs = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setMostrarDialogDeletar(false);
    setDropdownDiaSemanaValue(null);
    setDropdownTurnoColetaValue(null);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(false);
  };

  useEffect(() => {
    let temPermissao = false;
    const tokenJWT: any = keycloak?.token;
    const decodedHeader: any = jwt_decode(tokenJWT);
    const { roles } = decodedHeader.realm_access;

    for (let i = 0; i < roles.length; i += 1) {
      if (roles[i] === '5R_APP_GER_COL') {
        temPermissao = true;
        consultarDadosTabela();
      }
      if (i + 1 === roles.length && temPermissao === false) {
        props.history.push('/');
      }
    }
  }, []);

  const diaSemanaTexto = (diaSemana) => {
    const descricaoDiaSemana = dropdownDiaSemanaOpcoes.find((e) => e.valor === diaSemana)?.texto;
    return descricaoDiaSemana;
  };

  const templateDiaSemana = (rowData) => {
    return diaSemanaTexto(rowData.diaSemana);
  };

  const acaoSalvarDados = () => {
    setSalvando(true);
    setTentarSalvar(true);

    if (dropdownDiaSemanaValue !== 'undefined') {
      dadosSalvar.diaSemana = dropdownDiaSemanaValue;
    } else {
      setSalvando(false);
      return;
    }

    if (dropdownTurnoColetaValue) {
      dadosSalvar.turnoColeta = dropdownTurnoColetaValue;
    } else {
      setSalvando(false);
      return;
    }

    if (dadosSalvar.bairro.trim()) {
      if (dadosSalvar.id === '') {
        basicoService.post('/v1/coletas-seletivas', dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados salvos com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          consultarDadosTabela();
        });
      } else {
        basicoService.put(`/v1/coletas-seletivas/${dadosSalvar.id}`, dadosSalvar).then(() => {
          mostrarToast('success', 'Sucesso!', 'Dados editados com sucesso.');
          setSalvando(false);
          acaoFecharDialogs();
          consultarDadosTabela();
        });
      }
    } else {
      setSalvando(false);
    }
  };

  const acaoDeletarDados = () => {
    setTentarSalvar(true);
    basicoService.delete(`/v1/coletas-seletivas/${dadosSalvar.id}`).then(() => {
      mostrarToast('success', 'Sucesso!', 'Dados deletados com sucesso.');
      setSalvando(false);
      consultarDadosTabela();
    });
    setMostrarDialogDeletar(false);
    setDadosSalvar(dadosSalvarVazio);
  };

  const acaoMostrarDialogCriar = () => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar(dadosSalvarVazio);
    setMostrarDialogCriarEditar(true);
  };

  const acaoMostrarDialogEditar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogCriarEditar(true);

    if (typeof dados.diaSemana !== 'undefined') setDropdownDiaSemanaValue(dados.diaSemana);
    if (dados.turnoColeta) setDropdownTurnoColetaValue(dados.turnoColeta);
  };

  const acaoMostrarDialogDeletar = (dados) => {
    setSalvando(false);
    setTentarSalvar(false);
    setDadosSalvar({ ...dados });
    setMostrarDialogDeletar(true);
  };

  const acaoInputDigitar = (e, dados) => {
    if (dados === 'diaSemana') {
      setDropdownDiaSemanaValue(e.value);
    } else if (dados === 'turnoColeta') {
      setDropdownTurnoColetaValue(e.value);
    } else {
      const val = (e.target && e.target.value) || '';
      const _dadosSalvar = { ...dadosSalvar };
      _dadosSalvar[`${dados}`] = val;
      setDadosSalvar(_dadosSalvar);
    }
  };

  const cabecalhoTabela = (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="table-header">
        <h5 className="p-m-0">Consulta de Coletas Seletivas</h5>
      </div>
      <Button label="Novo" icon="pi pi-plus" className="p-button-info p-mr-2" onClick={acaoMostrarDialogCriar} />
    </div>
  );

  const botoesRodapeDialog = (
    <div style={{ paddingTop: '5px' }}>
      <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Salvar"
        icon="pi pi-check"
        className="p-button-success"
        onClick={acaoSalvarDados}
      />
    </div>
  );

  const deleteTipoProtocoloDialogFooter = (
    <div style={{ paddingTop: '4px' }}>
      <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={acaoFecharDialogs} />
      <Button
        disabled={salvando}
        label="Sim"
        icon="pi pi-check"
        className="p-button-danger"
        onClick={acaoDeletarDados}
      />
    </div>
  );

  const templateBotoesTabela = (rowData) => {
    return (
      <>
        <Button
          style={{ marginRight: '15px' }}
          icon="pi pi-pencil"
          className="p-button-rounded p-button-outlined"
          onClick={() => acaoMostrarDialogEditar(rowData)}
          tooltip="Editar"
          tooltipOptions={{ position: 'top' }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-outlined p-button-danger"
          onClick={() => acaoMostrarDialogDeletar(rowData)}
          tooltip="Excluir"
          tooltipOptions={{ position: 'top' }}
        />
      </>
    );
  };

  return (
    <div style={{ height: '90%' }}>
      {!carregandoDados && (
        <div>
          <div className="card" style={{ margin: '20px' }}>
            <DataTable
              emptyMessage="Sem dados"
              value={dadosTabela}
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10]}
              resizableColumns
              header={cabecalhoTabela}
            >
              <Column field="bairro" header="Descrição" />
              <Column field="diaSemana" header="Dia da Semana" body={templateDiaSemana} />
              <Column field="turnoColeta" header="Turno da Coleta" />
              <Column bodyStyle={{ textAlign: 'end' }} body={templateBotoesTabela} />
            </DataTable>
          </div>
        </div>
      )}

      {carregandoDados && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }} />
            <p style={{ marginTop: 0 }}>Carregando</p>
          </div>
        </div>
      )}

      <Dialog
        visible={mostrarDialogCriarEditar}
        style={{ width: '600px' }}
        header="Cadastro de Coleta Seletiva"
        modal
        className="p-fluid"
        footer={botoesRodapeDialog}
        onHide={acaoFecharDialogs}
      >
        <div className="p-fluid p-grid">
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="bairro">Descrição</label>
            <InputText
              maxLength={50}
              id="bairro"
              value={dadosSalvar.bairro}
              onChange={(e) => acaoInputDigitar(e, 'bairro')}
              required
              autoFocus
              className={classNames({ 'p-invalid': tentarSalvar && !dadosSalvar.bairro })}
            />{' '}
            {tentarSalvar && !dadosSalvar.bairro && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="diaSemana">Dia da Semana</label>
            <Dropdown
              style={{ marginTop: '5px' }}
              value={dropdownDiaSemanaValue}
              options={dropdownDiaSemanaOpcoes}
              onChange={(e) => acaoInputDigitar(e, 'diaSemana')}
              optionLabel="texto"
              optionValue="valor"
              placeholder="Selecione o dia"
              className={classNames({ 'p-invalid': tentarSalvar && dropdownDiaSemanaValue === null })}
            />{' '}
            {tentarSalvar && dropdownDiaSemanaValue === null && <small className="p-error">Campo obrigatório.</small>}
          </div>
          <div className="p-sm-12 p-md-12 p-lg-12">
            <label htmlFor="turnoColeta">Turno da Coleta</label>
            <Dropdown
              style={{ marginTop: '5px' }}
              value={dropdownTurnoColetaValue}
              options={dropdownTurnoColetaOpcoes}
              onChange={(e) => acaoInputDigitar(e, 'turnoColeta')}
              optionLabel="texto"
              optionValue="valor"
              placeholder="Selecione o turno"
              className={classNames({ 'p-invalid': tentarSalvar && !dropdownTurnoColetaValue })}
            />{' '}
            {tentarSalvar && !dropdownTurnoColetaValue && <small className="p-error">Campo obrigatório.</small>}
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={mostrarDialogDeletar}
        style={{ width: '450px' }}
        header="Aviso!"
        modal
        footer={deleteTipoProtocoloDialogFooter}
        onHide={acaoFecharDialogs}
      >
        <div style={{ display: 'flex', alignItems: 'center' }} className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem', marginRight: '5px' }} />
          {dadosSalvar && (
            <span>
              Deseja remover <b>{dadosSalvar.bairro}</b>?
            </span>
          )}
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};

export default ColetasSeletivas;
