/* eslint-disable no-buffer-constructor */

import axios from 'axios';
import { authHttp } from '../http';

export const USER_DETAILS = '/v1/users/details';
export const USER = '/api/users';

export const getUserByUsername = (payload) => {
  return authHttp
    .get(USER_DETAILS, { params: { username: payload.username } })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default () => ({
  getManutencaoProtocolos(numeroProtocolo, situacao, idResponsavel) {
    let urlFull = `/v1/protocolos/consulta-manutencao?status=${situacao}&size=999999`;

    if (numeroProtocolo) {
      urlFull = `${urlFull}&numeroProtocolo=${numeroProtocolo}`;
    }
    if (idResponsavel) {
      urlFull = `${urlFull}&idResponsavel=${idResponsavel}`;
    }

    return authHttp
      .get(process.env.REACT_APP_KSI_URL_5R + urlFull, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postManutencaoProtocolos(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  putManutencaoProtocolos(dados, id) {
    return authHttp
      .put(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo/${id}`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  cancelamentoManutencaoProtocolos(id, dados) {
    return authHttp
      .put(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos/${id}/cancelar`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getManutencaoProtocolosJson() {
    return axios.get('data/tipos-protocolo.json').then((res) => res.data.data);
  },

  getDadosImovel(id) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/imoveis/${id}`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getOcorrencias(id) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/protocolos/${id}/consulta-detalhado?size=999999`, {
        params: {},
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getIdArquivo(id) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos-itens?idArquivo=${id}`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getUsuarios(pesquisa) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/usuarios/consulta-usuario?size=999999&search=${pesquisa}`, {
        params: {},
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postOcorrencia(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_5R}/v1/ocorrencias-protocolo`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getImagem() {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/mostrarArquivo/9399149a-667f-4a20-9942-f93f2c4ca81d`, {
        params: {},
      })
      .then((resp) => {
        const data = `data:${resp.headers['content-type']};base64,${new Buffer(resp.data).toString('base64')}`;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getImagemA(idImagem) {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/mostrarArquivo/${idImagem}`, { params: {} })
      .then((resp) => {
        const data = `data:${resp.headers['content-type']};base64,${new Buffer(resp.data).toString('base64')}`;
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postNomeArquivos(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/salvar-lista`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postArquivos(idArquivo, arquivo) {
    const formData = new FormData();
    formData.append('file', arquivo);

    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/${idArquivo}/upload-arquivo`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  baixarImagemS3(dadosImg) {
    let tipoArquivo: string = dadosImg.nomeArquivo.replace(/"/g, '').slice(dadosImg.nomeArquivo.lastIndexOf('.'));

    if (tipoArquivo === '.jpg') {
      tipoArquivo = '.jpeg';
    }

    fetch(
      `http://${process.env.REACT_APP_BUCKET_NAME}.s3.sa-east-1.amazonaws.com/${process.env.REACT_APP_DIR_NAME}/${
        dadosImg.id + tipoArquivo
      }`,
      {
        method: 'GET',
      },
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', dadosImg.nomeArquivo);

        document.body.appendChild(link);

        link.click();

        link?.parentNode?.removeChild(link);
      });
  },

  baixarImagemBackend(dadosImg) {
    return authHttp
      .get(
        `${process.env.REACT_APP_KSI_URL_CORE}/v1/arquivos/download/${dadosImg.id}
        `,
        {
          responseType: 'blob',
        },
      )
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', dadosImg.nomeArquivo);
        document.body.appendChild(link);
        link.click();
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
});
