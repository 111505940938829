import axios from 'axios';
import { authHttp } from '../http';

export const USER = '/api/users';
export const USER_DETAILS = '/v1/users/details';

export const getUserByUsername = (payload) => {
  return authHttp
    .get(USER_DETAILS, { params: { username: payload.username } })
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default () => ({
  getTiposProtocolo() {
    return authHttp
      .get(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  postTiposProtocolo(dados) {
    return authHttp
      .post(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  putTiposProtocolo(dados, id) {
    return authHttp
      .put(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo/${id}`, dados, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  deleteTiposProtocolo(id) {
    return authHttp
      .delete(`${process.env.REACT_APP_KSI_URL_5R}/v1/tipos-protocolo/${id}`, { params: {} })
      .then((resp) => {
        return resp.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  getTiposProtocoloJson() {
    return axios.get('data/tipos-protocolo.json').then((res) => res.data.data);
  },
});
